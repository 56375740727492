import React from "react";
import {formatReefer, prettyDate, prettyDateTime, setTitle} from "../../../common/shared";
import axios from "axios";
import {DataGrid, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarContainer} from "@mui/x-data-grid";
import {
    Autocomplete, Box, Checkbox, CircularProgress, FormControl, InputLabel, Link, ListItemText, MenuItem, OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import EditIcon from '@mui/icons-material/Edit';
import ContainerIcon from "../../../icons/ContainerIcon";
import {default as RemoveFromOrderIcon} from '@mui/icons-material/Deselect';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import OrderContainerDialog from "./OrderContainerDialog";
import SelectOrderDialog from "./SelectOrderDialog";
import {useSnackbar} from "notistack";
import AddOrderIcon from "../../../icons/AddOrderIcon";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {useNavigate, useSearchParams} from "react-router-dom";
import Actions from "./Actions";
import ActionIcon from "../../../components/ActionIcon";
import {createBrowserHistory} from "history";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileInput from "../../../components/FileInput";

const SPECIAL_FILTERS = {
    'UNOWNED_PRESENT': 'Without Shipping List - In Port',
    'NOBOOK_PRESENT': 'Without Booking Ref - In Port',
    'DUE_PRESENT': 'Due & In Port'
};

// This is the key in localstorage where we save the column settings
const COLS_KEY = 'admin/OrderContainer/cols';
const NUM_ROWS_KEY = 'admin/OrderContainer/numRows';


export default function OrderContainer(props) {
    React.useEffect(() => setTitle('Container Administration'), []);

    const history = createBrowserHistory();
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(() => {
        const sz = parseInt(localStorage.getItem(NUM_ROWS_KEY));
        if ([10, 25, 50, 100].includes(sz)) return sz;
        return 25;
    });
    const [page, setPage] = React.useState(0);
    const [ocs, setOcs] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [sortModel, setSortModel] = React.useState(undefined);
    const actionTypeById = useLiveQuery(
        () => db.ActionType.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {}))
        , [], {});

    const allStatuses = useLiveQuery(() => db.ContainerStatus.toArray(), [], []);

    // This is the current oc we're dealing with explicitly (could be adding to an order, editing, viewing actions etc)
    const [ocUuid, setOcUuid] = React.useState(null);

    // And this is what we're doing with it
    const [operation, setOperation] = React.useState(null);

    //const [editDialogVisible, setEditDialogVisible] = React.useState(false);
    //const [selectOrderDialogVisible, setSelectOrderDialogVisible] = React.useState(false);
    //const [selectOrderForOc, setSelectOrderForOc] = React.useState(undefined);

    // Dummy state which we can modify to re-query the db.
    const [dummy, setDummy] = React.useState(0);

    // filters
    const [portCode, setPortCode] = React.useState(history.location.state?.portCode || null);
    const [depotId, setDepotId] = React.useState(history.location.state?.depotId || null);
    const [customerId, setCustomerId] = React.useState(history.location.state?.customerId || null);
    const [ocStatus, setOcStatus] = React.useState(history.location.state?.ocStatus || null);
    const [reeferNum, setReeferNum] = React.useState(history.location.state?.reeferNum || null);
    const [specialFilter, setSpecialFilter] = React.useState(history.location.state?.specialFilter || null);
    const [lastActionTypeIds, setLastActionTypeIds] = React.useState(history.location.state?.lastActionTypeIds || []);

    // Handle to drop the columns menu from
    const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

    // DB 2024-01-15: This stuff is gone now, as they want the filter to default to ALL by default.
    // On first render, if there are unowned containers with status PRESENT, we set the special filter to show them.
    // React.useEffect(() => {
    //     // This is messy, but it's the best way I can find to do this.
    //     // Need this run ONLY when specialFilter hasn't been loaded from the history, and only once (at startup).
    //     // But we need specialFilter in here as a dependency (coz we need to check it), so actually this runs every
    //     // time specialFilter changes (including at startup).
    //     // So we check that specialFilter is undefined (which it should only be at startup, and only if the history
    //     // doesn't have it) before setting it automatically.
    //     if (specialFilter === undefined) {
    //         db.OrderContainer.where('status').equals('PRESENT')
    //             .filter(oc => !oc.order_id).count((numUnlinked) => {
    //             if (numUnlinked > 0) {
    //                 setSpecialFilter('UNOWNED_PRESENT');
    //             } else {
    //                 setSpecialFilter(null);
    //             }
    //         });
    //     }
    // }, [specialFilter]);

    // When portCode changes, store it in the location history
    React.useEffect(() => {
        try {
            history.replace(null, {
                ...history.location.state,
                portCode,
                depotId,
                customerId,
                ocStatus,
                reeferNum,
                specialFilter,
                lastActionTypeIds
            });
        }
        catch (e) {
            console.log(e);
        }
    }, [customerId, depotId, history, ocStatus, portCode, reeferNum, specialFilter, lastActionTypeIds]);


    React.useEffect(() => {
        setLoading(true);
        if (specialFilter === undefined) return;    //  not done loading yet.
        const params = {
            dummy,
            pageSize,
            page,
            sortModel,
            portCode,
            depotId,
            customerId,
            ocStatus,
            specialFilter,
            reeferNum,
            lastActionTypeIds,
        }
        // Note: even though this is really a 'get', we use post, as get doesn't handle json very well.
        axios.post('/admin/orderContainer', params)
            .then(
                (response) => {
                    const data = response.data;
                    if (!data) {
                        setError('Missing data in response');
                    }
                    else {
                        if (data.error) setError(data.message);
                        else {
                            setOcs(data.rows);
                            setRowCount(data.numRows);
                        }
                    }
                },
                (error) => {
                    setError(error.response?.data?.message || error.toString());
                })
            .finally(() => setLoading(false));
    }, [dummy, pageSize, page, sortModel, portCode, depotId, customerId, ocStatus, specialFilter, reeferNum, lastActionTypeIds]);

    const downloadReport = () => {
        const params = {
            format: 'xls',
            sortModel,
            portCode,
            depotId,
            customerId,
            ocStatus,
            specialFilter,
            reeferNum,
            lastActionTypeIds,
            columns,
            colVis,
        }
        console.log(params);

        axios.post('/admin/orderContainer', params, {responseType: 'blob'})
            .then((response) => {
                console.log(response, response.data);
                const blob = new Blob([response.data], {type: response.data.type});
                if (blob.type === 'application/json') {
                    // Errors come back as application/json, which get forced into a blob by our responseType
                    // requirement.  Need to convert them back to text and parse as json.
                    blob.text().then(text => {
                        const data = JSON.parse(text);
                        //setError(data.message);
                        enqueueSnackbar(data.message, {variant: 'error'});
                    });
                }
                else {
                    // Hacky way to force browser to save the file we just received.  Convert it to a data-url,
                    // put that in a link (with the download attribute set to the filename), and click the link.
                    const urlRef = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = urlRef;
                    if (response.headers['content-disposition']) {
                        const fileNameMatch = response.headers['content-disposition'].match(/filename="(.+)"/);
                        link.download = fileNameMatch ? fileNameMatch[1] : 'ContainerAdmin.xlsx';
                    }
                    else {
                        // No content-disposition header?  Go with a sensible default.
                        link.download = 'ContainerAdmin.xlsx';
                    }
                    document.body.appendChild(link);
                    link.click();
                    // then tidy up.
                    link.parentNode.removeChild(link);
                    window.URL.revokeObjectURL(urlRef);
                }

            })
            .catch((error) => {
                //setError(error.response?.data?.message || error.toString());
                enqueueSnackbar(error.response?.data?.message || error.toString(), {variant: 'error'});
            });
    };

    const onUploadUnplugs = React.useCallback(async (file, params) => {
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        await axios.post('/admin/orderContainer/uploadUnplugs', formData)
            .then((response) => {
                const data = response.data;
                if (data.error) {
                    enqueueSnackbar(data.message, {variant: 'error'});
                }
                else {
                    const failures = data.failures;
                    const successes = data.successes;
                    // if there are no failures, just give them the message:
                    if (failures.length === 0) {
                        enqueueSnackbar(data.message, {variant: 'success'});
                    }
                    else {
                        const msg = [data.message];
                        if (successes.length > 0) {
                            // just show the first 5 container numbers
                            let mt = 'Successfully updated: ' + successes.slice(0, 5).join(', ');
                            if (successes.length > 5) {
                                mt += ' (and ' + (successes.length - 5) + ' more)';
                            }
                            msg.push(mt);
                        }
                        // And only show the first 5 failures too...
                        failures.slice(0, 5).forEach(f => {
                            msg.push(f);
                        });
                        if (failures.length > 5) {
                            msg.push('... and ' + (failures.length - 5) + ' more');
                        }

                        // If there are failures, this is a warning.
                        enqueueSnackbar(<ul>{
                            msg.map((m, idx) => {
                                return <li key={idx}>{m}</li>;
                            })
                        }</ul>, {variant: 'warning'});
                    }
                    setDummy(dummy + 1);
                }
            })
            .catch((error) => {
                enqueueSnackbar(error.response?.data?.message || error.toString(), {variant: 'error'});
            });
    }, [dummy, enqueueSnackbar]);

    // Listen for changes to the search params, and update the state accordingly.
    React.useEffect(() => {
        const ocUrl = searchParams.get('oc');
        if (ocUrl) {
            //  Only bother changing it if we actually need to.
            if (ocUrl !== ocUuid) {
                setOcUuid(ocUrl);
            }
        }
        else {
            setOcUuid(null);
        }

        const opUrl = searchParams.get('op');
        if (opUrl) {
            if (opUrl !== operation) {
                setOperation(opUrl);
            }
        }
        else {
            setOperation(null);
        }
    }, [searchParams, ocUuid, operation]);

    const editOc = React.useCallback((uuid) => {
        // Can't just set it directly now, need to put it in the url also.
        searchParams.set('oc', uuid);
        searchParams.set('op', 'edit');
        setSearchParams(searchParams, {replace: true});
        //setOcUuid(uuid);
        //setOperation('edit');
    }, [searchParams, setSearchParams]);

    const showOcActions = React.useCallback((uuid) => {
        console.log('showOcActions', uuid);
        searchParams.set('oc', uuid);
        searchParams.set('op', 'actions');
        setSearchParams(searchParams, {replace: false});
    }, [searchParams, setSearchParams]);

    const deleteOc = React.useCallback((uuid) => {
        if (window.confirm('Are you sure you want to delete this container?')) {
            axios.post('/admin/orderContainer/delete', {uuid})
                .then(
                    (response) => {
                        const data = response.data;
                        if (!data) {
                            enqueueSnackbar('Missing data in response', {
                                variant: 'error'
                            });
                        }
                        else {
                            if (data.error) {
                                enqueueSnackbar(data.message || 'Failed to delete container', {
                                    variant: 'error'
                                });
                            }
                            else {
                                enqueueSnackbar(data.message || 'Container deleted successfully', {
                                    variant: 'success'
                                });
                                if (ocUuid === uuid && operation === 'edit') {
                                    //setOperation(null);
                                    // Better to do it this way instead...
                                    setSearchParams({}, {replace: true});
                                }
                                setDummy(dummy + 1); // trigger a refresh
                            }
                        }
                    },
                    (error) => {
                        enqueueSnackbar(error.response?.data?.message || error.toString(), {
                            variant: 'error'
                        });
                    }
                );
        }
    }, [dummy, enqueueSnackbar, ocUuid, operation, setSearchParams]);

    const handleAddToOrder = React.useCallback((oc) => {
        // setSelectOrderForOc(oc);
        // setSelectOrderDialogVisible(true);
        searchParams.set('oc', oc.uuid);
        searchParams.set('op', 'addToOrder');
        setSearchParams(searchParams, {replace: true});
    }, [searchParams, setSearchParams]);

    const onAddToOrder = (order) => {
        console.log('onAddToOrder', order, ocUuid);
        // setSelectOrderDialogVisible(false);
        setSearchParams({}, {replace: true});    //  clear 'oc' and 'op'
        if (order.id && ocUuid) {
            axios.post('/admin/orderContainer/update',
                // Only send the uuid and the order_id, as we're not updating anything else
                {uuid: ocUuid, order_id: order.id})
                .then(
                    (response) => {
                        const data = response.data;
                        if (!data) {
                            enqueueSnackbar('Failed to update container: missing data in response', {
                                variant: 'error'
                            });
                        }
                        else {
                            if (data.error) {
                                enqueueSnackbar(data.message || 'Failed to update container', {
                                    variant: 'error'
                                });
                            }
                            else {
                                enqueueSnackbar(data.message || 'Container updated successfully', {
                                    variant: 'success'
                                });
                                setDummy(dummy + 1); // trigger a refresh
                            }
                        }
                    },
                    (error) => {
                        enqueueSnackbar(error.response?.data?.message || error.toString(), {
                            variant: 'error'
                        });
                    }
                );
        }
    }

    const handleRemoveFromOrder = React.useCallback( (oc) => {
        if (window.confirm('Remove this container from the Shipping List?\nThe container details and any associated actions will be retained.')) {

            if (ocUuid === oc.uuid && operation === 'edit') {
                setSearchParams({}, {replace: true});    //  clear 'oc' and 'op'
            }

            axios.post('/admin/orderContainer/update',
                // Only send the uuid and the order_id, as we're not updating anything else
                {uuid: oc.uuid, order_id: null})
                .then(
                    (response) => {
                        const data = response.data;
                        if (!data) {
                            enqueueSnackbar('Failed to update container: missing data in response', {
                                variant: 'error'
                            });
                        }
                        else {
                            if (data.error) {
                                enqueueSnackbar(data.message || 'Failed to update container', {
                                    variant: 'error'
                                });
                            }
                            else {
                                enqueueSnackbar(data.message || 'Container has been removed from the order', {
                                    variant: 'success'
                                });
                                setDummy(dummy + 1); // trigger a refresh
                            }
                        }
                    },
                    (error) => {
                        enqueueSnackbar(error.response?.data?.message || error.toString(), {
                            variant: 'error'
                        });
                    }
                );
        }
    }, [dummy, enqueueSnackbar, ocUuid, operation, setSearchParams]);

    const showEngineersView = React.useCallback((uuid) => {
        navigate(`/user/containers?oc=${uuid}`);
    }, [navigate]);

    const onPageSizeChange = (newSize) => {
        localStorage.setItem(NUM_ROWS_KEY, newSize)
        setPageSize(newSize);
    };

    const [colVis, setColVis] = React.useState(() => {
        const c = localStorage.getItem(COLS_KEY);
        if (c) return JSON.parse(c);
        return {
            order_id: false,
            'depot.entry_type': false,
            in_at: false,
            status: false,
            out_at: false,
            ship: false,
            sail_date: false,
            // voyage_number: false,  removed per lankel/lankel#34
            customer_note: false,
            booking_ref: false,
            unplug_only: false,
        }
    })
    const onColVisChange = (newVis) => {
        console.log(newVis);
        setColVis(newVis);
        localStorage.setItem(COLS_KEY, JSON.stringify(newVis));
    }

    const columns = React.useMemo(() => [
        {
            field: 'reefer_num',
            headerName: 'Unit Number',
            hideable: false,
            width: 130,
            valueFormatter: ({value}) => formatReefer(value),
            cellClassName: 'reefer-num'
        },
        {
            field: 'set_temp',
            headerName: 'Set Point',
            type: 'number',
            width: 80,
            valueFormatter: ({value}) => value === null ? '-' : Number(value).toFixed(1) + '°C',
        },
        {
            field: 'actions',
            headerName: 'Options',
            hideable: false,
            type: 'actions',
            width: 130,
            align: 'right',
            getActions: (params) => {
                const acts = [];

                if (!params.row.order_id) {
                    acts.push(<GridActionsCellItem icon={<AddOrderIcon />}
                                                   label="Add to Shipping List"
                                                   title="Add to Shipping List"
                                                   onClick={() => handleAddToOrder(params.row)}
                    />);
                }
                else {
                    acts.push(<GridActionsCellItem icon={<RemoveFromOrderIcon />}
                                                   title="Remove this container from the Shipping List (without deleting the container records)"
                                                   label="Remove from Shipping List"
                                                   showInMenu
                                                   onClick={() => {
                                                       handleRemoveFromOrder(params.row);
                                                   }}
                    />);
                }

                acts.push(<GridActionsCellItem icon={<TaskAltIcon />}
                                               title="Show actions for this container"
                                               label="Action History"
                                               onClick={() => showOcActions(params.row.uuid)}
                                               showInMenu />);
                //<Route path="invoice" element={<Invoice />} /> <- DB: not sure why this is here
                acts.push(<GridActionsCellItem icon={<EditIcon />}
                                               title="Edit Container Record"
                                               label="Edit"
                                               onClick={() => editOc(params.row.uuid)} />);
                acts.push(<GridActionsCellItem icon={<ContainerIcon />}
                                               title="Show Engineer's view of this container"
                                               label="Engineer's View"
                                               onClick={() => showEngineersView(params.row.uuid)}
                                               showInMenu />);
                if (!params.row.invoice_id) {
                    acts.push(<GridActionsCellItem icon={<DeleteIcon/>}
                                                   title="Delete this container record (and all associated actions)"
                                                   label="Delete"
                                                   onClick={() => deleteOc(params.row.uuid)}
                                                   showInMenu/>);
                }

                return acts;
            }
        },
        {
            field: 'customer.name',
            headerName: 'Customer',
            minWidth: 80,
            flex: 1.5,
            valueGetter: ({row}) => row.customer?.name ?? row.container_customer?.name,
            renderCell: renderCellTooltip,
        },
        {
            field: 'cargo_description',
            headerName: 'Cargo',
            minWidth: 80,
            flex: 1.5,
            renderCell: renderCellTooltip,
        },
        {
            field: 'port.name',
            headerName: 'Port',
            minWidth: 50,
            flex: 0.5,
            valueGetter: ({row}) => row.port?.name,
            renderCell: renderCellTooltip,
        },
        {
            field: 'depot.name',
            headerName: 'Terminal',
            minWidth: 80,
            flex: 1,
            valueGetter: ({row}) => row.depot?.name,
            renderCell: renderCellTooltip,
        },
        {
            field: 'depot.entry_type',
            headerName: 'Location Type',
            minWidth: 50,
            flex: 1,
            valueGetter: ({row}) => ({
                DEPOT: 'Depot',
                TERMINAL: 'Terminal',
                BLANK: '- Not Set -'
            }[row.depot?.entry_type]),
            renderCell: renderCellTooltip,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 50,
            maxWidth: 100,
            flex: 1,
            valueGetter: ({value}) => allStatuses.find(s => s.status === value)?.status_name ?? value,
            cellClassName: ({row}) => `status-${row.status}`,
            renderCell: renderCellTooltip,
        },
        {
            field: 'ie',
            headerName: 'I/E Cycle',
            width: 75,
            renderCell: renderCellTooltip,
        },
        {
            field: 'in_at',
            headerName: 'Arrived At',
            type: 'dateTime',
            minWidth: 90,
            maxWidth: 110,
            flex: 1,
            valueFormatter: ({value}) => prettyDateTime(value, {isMagic: true}),
            renderCell: renderCellTooltip,
        },
        {
            field: 'out_at',
            headerName: 'Dispatched At',
            type: 'dateTime',
            minWidth: 90,
            maxWidth: 110,
            flex: 1,
            valueFormatter: ({value}) => prettyDateTime(value, {isMagic: true}),
            renderCell: renderCellTooltip,
        },
        {
            field: 'lastAction',
            headerName: 'Last Action Today',
            minWidth: 50,
            maxWidth: 230,
            flex: 1,
            sortable: false,
            // valueGetter only needed so we can sort by action label.
            valueGetter: ({row}) => row.lastAction ? `${actionTypeById[row.lastAction.action_type_id]?.label}` : null,
            renderCell: ({row}) => {
                const actionType = actionTypeById[row.lastAction?.action_type_id];
                if (!actionType) return null;
                return (
                    <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                        <ActionIcon iconData={actionType?.icon_data}
                                    sx={{color: actionType?.icon_color, alignSelf: 'center',mr: '8px'}}/>
                        <span>
                                {actionType?.label}
                        </span>
                    </Box>
                )
            }
        },
        {
            // removed due_date field, and replaced with this
            field: 'order.due_date',
            headerName: 'Due Date',
            type: 'date',
            width: 90,
            valueGetter: ({row}) => row.order?.due_date,
            valueFormatter: ({value}) => prettyDate(value),
            renderCell: renderCellTooltip,
        },
        {
            field: 'sail_date',
            headerName: 'Vessel Date',
            type: 'date',
            width: 90,
            valueFormatter: ({value}) => prettyDate(value),
            renderCell: renderCellTooltip,
        },
        {
            field: 'ship',
            headerName: 'Ship',
            minWidth: 80,
            flex: 1.5,
            renderCell: renderCellTooltip,
        },
        // hide these two per: lankel/lankel#34
        // {
        //     field: 'release_no',
        //     headerName: 'Release No',
        //     minWidth: 80,
        //     flex: 1,
        //     renderCell: renderCellTooltip,
        // },
        // {
        //     field: 'voyage_number',
        //     headerName: 'Voyage Number',
        //     minWidth: 80,
        //     flex: 1,
        //     renderCell: renderCellTooltip,
        // },
        {
            field: 'customer_note',
            headerName: 'Note (Customer)',
            minWidth: 80,
            flex: 1,
            renderCell: renderCellTooltip,
        },
        {
            field: 'order_id',
            headerName: 'Shipping List ID',
            width: 75,
            type: 'number',
            renderCell: renderCellTooltip,
        },
        {
            field: 'order.booking_ref',
            headerName: 'Ship Ref',  // DB 2023-01-16: Renamed to avoid confusion with new booking_ref field in oc.
            minWidth: 80,
            flex: 1,
            valueGetter: ({row}) => row.order?.booking_ref,
            renderCell: renderCellTooltip,
        },
        {
            field: 'booking_ref',
            headerName: 'Booking Ref',
            minWidth: 80,
            flex: 1,
            renderCell: renderCellTooltip,
        },
        // {
        //     field: 'numActions',
        //     headerName: '# Actions',
        //     minWidth: 50,
        //     flex: 0.5,
        //     type: 'number',
        // },

        {
            field: 'unplug_only',
            headerName: 'Unplug-Only',
            width: 80,
            type: 'boolean',
            valueGetter: ({value}) => !!value,
        }
    ], [handleRemoveFromOrder, deleteOc, showEngineersView, handleAddToOrder, editOc, showOcActions,
        allStatuses, actionTypeById]);

    const statusColCssClasses = React.useMemo(() => {
        const classes = {};
        allStatuses.forEach(s => classes[`& .status-${s.status}`] = {
            borderLeftWidth: '4px',
            borderLeftStyle: 'solid',
            borderLeftColor: s.color,
        });
        return classes;
    }, [allStatuses]);

    return (
        <Box sx={{ height: '100%', position: 'relative' }}>
            <DataGrid
                density="compact"
                disableSelectionOnClick
                columns={columns}
                rows={ocs ?? []}
                loading={loading}
                error={error}
                sx={{
                    position: 'absolute',
                    top: 0, left: 0, right: 0, bottom: 0,
                    visibility: operation === 'actions' ? 'hidden' : 'visible',
                    borderRadius: 0,
                    bgcolor: '#ffffff',
                    '& .MuiDataGrid-cell:focus': {
                        outline: "none",
                    },
                    '& .reefer-num > div': {
                        textAlignLast: 'justify',
                        width: '100%'
                    },
                    ...statusColCssClasses
                }}
                hideFooterSelectedRowCount
                getRowId={(r) => r.uuid}
                rowBuffer={10}
                page={page}
                onPageChange={(p) => setPage(p)}
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
                rowsPerPageOptions={[10, 25, 50, 100]}
                rowCount={rowCount}
                paginationMode="server"
                sortingMode="server"
                filterMode="server"
                onSortModelChange={(sm) => setSortModel(sm)}
                disableColumnFilter
                disableDensitySelector
                disableColumnMenu
                columnVisibilityModel={colVis}
                onColumnVisibilityModelChange={onColVisChange}
                components={{
                    Toolbar: CustomToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                componentsProps={{
                    panel: {
                        anchorEl: columnsButtonEl
                    },
                    toolbar: { portCode, setPortCode, depotId, setDepotId, customerId, setCustomerId,
                        ocStatus, setOcStatus, specialFilter, setSpecialFilter, reeferNum, setReeferNum,
                        lastActionTypeIds, setLastActionTypeIds,
                        setColumnsButtonEl, downloadReport, uploadUnplugs: onUploadUnplugs
                    },
                    noRowsOverlay: {
                        portCode, depotId, customerId, ocStatus, specialFilter, setSpecialFilter, reeferNum
                    }
                }}
            />
            {
                operation === 'edit' &&
                <OrderContainerDialog
                    uuid={ocUuid}
                    afterSave={() => {
                        // setEditDialogVisible(false);
                        setSearchParams({}, {replace: true}); // clear 'oc' and 'op'
                        setDummy(dummy + 1);
                    }}
                    onCancel={() => setSearchParams({}, {replace: true})}
                    deleteFn={deleteOc}
                    addToOrderFn={handleAddToOrder}
                />
            }
            {
                operation === 'addToOrder' &&
                <SelectOrderDialog
                    title='Add to Shipping List'
                    ocUuid={ocUuid}
                    //portCode={selectOrderForOc.depot?.port_code ?? null}
                    onCancel={() => setSearchParams({}, {replace: true})}
                    onOrderSelected={onAddToOrder} />
            }
            {
                operation === 'actions' &&
                <Actions uuid={ocUuid}
                         onClose={() => setSearchParams({}, {replace: true})}
                />
            }
        </Box>
    );
}


const GridCellTooltip = React.memo(function GridCellTooltip(props) {
    /**
     * This just renders the cell with the value in a tooltip.
     */
    const { value } = props;

    return (
        <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            title={value}>{value}</Box>
    );
});
GridCellTooltip.propTypes = {
    value: PropTypes.string.isRequired,
};

function renderCellTooltip(params) {
    // console.log(params);
    return (
        <GridCellTooltip value={params.formattedValue || ''} />
    );
}
renderCellTooltip.propTypes = {
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.object.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.string,
};



function CustomNoRowsOverlay(props) {
    // We have a custom no-rows message, so we can prompt them to remove the 'specialFilter' when present.
    const {portCode, depotId, customerId, ocStatus, specialFilter, setSpecialFilter, reeferNum} = props;

    const hasOtherFilter = (isNonEmpty(portCode) || isNonEmpty(depotId) || isNonEmpty(customerId) ||
        isNonEmpty(ocStatus) || isNonEmpty(reeferNum));

    const linkProps = {
        sx: {zIndex: 5},
        component: 'button',
        variant: 'body2',
        onClick: () => setSpecialFilter(null),
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        }}>{(() => {
            if (hasOtherFilter && specialFilter) {
                // offer to remove the filter
                if (specialFilter === 'UNOWNED_PRESENT') {
                    return (
                        <>
                            <div>No matching containers, with status 'In-Port', without an order found.</div>
                            <Link {...linkProps}>Repeat the search, including containers with orders.</Link>
                        </>
                    );
                }
                if (specialFilter === 'NOBOOK_PRESENT') {
                    return (
                        <>
                            <div>No matching containers, with status 'In-Port', without an booking ref found.</div>
                            <Link {...linkProps}>Repeat the search, including containers with a booking ref.</Link>
                        </>
                    );
                }
                if (specialFilter === 'DUE_PRESENT') {
                    return (
                        <>
                            <div>No matching containers found.</div>
                            <Link {...linkProps}>Repeat the search, including dispatched containers.</Link>
                        </>
                    );
                }
            }
            return 'No matching containers found.'
        })()}</div>
    );
}

const isNonEmpty = (v) => ((v !== '' && v != null) || (Array.isArray(v) && v?.length > 0));

function CustomToolbar({portCode, setPortCode, depotId, setDepotId, customerId, setCustomerId, ocStatus, setOcStatus,
                           specialFilter, setSpecialFilter, reeferNum, setReeferNum,
                           lastActionTypeIds, setLastActionTypeIds,
                           setColumnsButtonEl, downloadReport,
                           uploadUnplugs
                       }) {

    const ports = useLiveQuery(() => db.Port.toArray(), []);
    const depots = useLiveQuery(() => db.Depot.orderBy('name').toArray(), []);
    const customers = useLiveQuery(() => db.Customer.orderBy('name').toArray(), []);
    const allStatuses = useLiveQuery(() => db.ContainerStatus.orderBy('sort_order').toArray(), []);
    const allActionTypes = useLiveQuery(() => db.ActionType.where('deleted').equals(0).sortBy('sort_order'), []);
    const [unplugsUploadRunning, setUnplugsUploadRunning] = React.useState(false);

    const dropdownsLoaded = ports && depots && customers && allStatuses && allActionTypes;

    // We keep a local reefer num here so we can debounce
    const [rnTemp, setRnTemp] = React.useState(reeferNum);

    const hasFilterApplied = isNonEmpty(specialFilter) || isNonEmpty(portCode) || isNonEmpty(depotId) ||
        isNonEmpty(customerId) || isNonEmpty(ocStatus) || isNonEmpty(reeferNum) || isNonEmpty(lastActionTypeIds);

    const clearFilters = () => {
        setPortCode(null);
        setDepotId(null);
        setCustomerId(null);
        setOcStatus(null);
        setSpecialFilter(null);
        setReeferNum(null);
        setRnTemp(null);
        setLastActionTypeIds(null);
    };

    // Debounce to set reeferNum. Delays the query for 300ms.
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setReeferNum(rnTemp);
        }, 300);

        return () => clearTimeout(timer);
    }, [rnTemp, setReeferNum]);

    const onActionTypeFilterChange = (event) => {
        // For a multi-select, this value must be an array.
        // Need to do a little changing here to make the 'All' (0) option work.
        const oldVal = lastActionTypeIds;
        let newVal = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;

        // If the newVal is empty, add the 'All' option
        if (newVal.length === 0) {
            newVal = [0];
        }
        else {
            // If they added the 'All' option, remove all the others
            if (newVal.includes(0) && !oldVal.includes(0)) {
                newVal = [0];
            }
            else {
                // Otherwise, if there's an option selected that isn't 'All', remove 'All'
                if (newVal.includes(0) && newVal.length > 1) {
                    newVal = newVal.filter((v) => v !== 0);
                }
            }
        }

        setLastActionTypeIds(newVal);
    }

    const onUploadUnplugs = async (file, params) => {
        console.log(file, params);
        setUnplugsUploadRunning(true);
        uploadUnplugs(file, params)
            .then((result) => {
                return result;
            })
            .finally(() => {
                setUnplugsUploadRunning(false);
            });
    };

    const fcCommonSx = {mr: 1, mt: 1, fontSize: 'inherit'};
    const fcCommonProps = { variant: 'outlined', size: 'small' };
    const selCommonProps = {
        sx: {fontSize: 'inherit'},
        //InputLabelProps: {shrink: true}
    };
    const lblCommonProps = {shrink: true};


    if (!dropdownsLoaded) {
        return (
            <GridToolbarContainer sx={{fontSize: '0.8125rem'}}>
                <CircularProgress size={24} />
            </GridToolbarContainer>
        );
    }


    return (
        <GridToolbarContainer sx={{fontSize: '0.8125rem'}}>
            <FormControl {...fcCommonProps} sx={{ ...fcCommonSx, minWidth: 80 }}>
                <InputLabel id="special-label" {...lblCommonProps}>Show Containers...</InputLabel>
                <Select
                    labelId="special-label"
                    value={specialFilter ?? ''}
                    label='Show Containers...'
                    displayEmpty
                    onChange={(e) => {
                        setSpecialFilter(e.target.value);
                        // If this is non-empty, it clears the status field
                        if (e.target.value) {
                            setOcStatus(null);
                        }
                    }}
                    {...selCommonProps}
                >
                    <MenuItem value="" sx={{color: 'text.disabled'}}>- All -</MenuItem>
                    {
                        Object.entries(SPECIAL_FILTERS).map(([k,v], i) => <MenuItem key={i} value={k}>{v}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <TextField
                id="reefer_num"
                name="reefer_num"
                placeholder="Reefer Num..."
                label='Reefer Number'
                type="search"
                variant="outlined"
                size="small"
                sx={{...fcCommonSx}}
                InputProps={{sx:{fontSize: 'inherit'}}}
                inputProps={{
                    size: 13
                }}
                InputLabelProps={{...lblCommonProps}}
                value={rnTemp ?? ''}
                onChange={(e) => setRnTemp(e.target.value)}
                />

            <FormControl {...fcCommonProps} sx={{ ...fcCommonSx, minWidth: 80 }}>
                <InputLabel id="port-label" {...lblCommonProps}>Port</InputLabel>
                <Select
                    labelId="port-label"
                    label='Port'
                    value={portCode ?? ''}
                    displayEmpty
                    onChange={(e) => {
                        setPortCode(e.target.value);
                        setDepotId(null);
                    }}
                    notched={true}
                    {...selCommonProps}
                >
                    <MenuItem value="" sx={{color: 'text.disabled'}}>- Port -</MenuItem>
                    {
                        ports.map(p => <MenuItem key={p.code} value={p.code}>{p.name}</MenuItem>)
                    }
                </Select>
            </FormControl>

            {(portCode !== '' && portCode != null) && <FormControl {...fcCommonProps} sx={{ ...fcCommonSx, minWidth: 80 }}>
                <InputLabel id="depot-label" {...lblCommonProps}>Terminal/Depot</InputLabel>
                <Select
                    labelId="depot-label"
                    label="Terminal/Depot"
                    value={depotId ?? ''}
                    displayEmpty
                    onChange={(e) => {
                        setDepotId(e.target.value);
                    }}
                    sx={{fontSize: 'inherit'}}
                    //InputProps={{sx:{fontSize: 'inherit'}}}
                    inputProps={{
                        size: 13
                    }}
                    notched={true}
                    {...selCommonProps}
                >
                    <MenuItem value="" sx={{color: 'text.disabled'}}>- Terminal/Depot -</MenuItem>
                    {
                        depots.filter(d => d.port_code === portCode)
                            // .map(p => <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>)
                            // This puts the TERMINALs first, then DEPOTs, then sorts within each by name
                            .sort((a, b) => -1*a.entry_type.localeCompare(b.entry_type) || a.name.localeCompare(b.name))
                            .map((d, i, arr) => <MenuItem
                                divider={i !== arr.length - 1 && arr[i+1].entry_type !== d.entry_type}
                                key={d.id} value={d.id}>{d.name}</MenuItem>)
                    }
                </Select>
            </FormControl>}


            <Autocomplete
                variant="outlined"
                id="combo-customer"
                sx={{
                    ...fcCommonSx,
                    '& .MuiInputBase-hiddenLabel': {
                        paddingTop: 0
                    }
                }}
                options={[{id: '', name: '- Customer -'}].concat(customers)}

                getOptionLabel={(option) => {
                    //console.log(option);
                    return option?.name ?? '- Customer -'
                }}
                isOptionEqualToValue={(option, value) => {
                    if (value == null || value === '') return false;
                    if (option?.id === value?.id) return true;
                    return option?.id === value;
                }}
                value={customers.find(c => c.id === customerId) ?? {id: '', name: ''}}
                onChange={(e, newVal) => setCustomerId(newVal?.id)}
                renderInput={(params) => <TextField
                    {...params}
                    // hiddenLabel
                    label="Customer"
                    variant="outlined" size="small"
                    sx={{ minWidth: 150, fontSize: 'inherit' }}
                    InputProps={{...params.InputProps, sx:{fontSize: 'inherit'}}}
                    InputLabelProps={{...lblCommonProps}}
                    placeholder='Customer'
                />}
            />

            <FormControl {...fcCommonProps} sx={{ ...fcCommonSx, minWidth: 80 }}>
                <InputLabel id="status-label" {...lblCommonProps}>Status</InputLabel>
                <Select
                    labelId="status-label"
                    label='Status'
                    value={ocStatus ?? ''}
                    displayEmpty
                    onChange={(e) => {
                        setOcStatus(e.target.value);

                        // When the special-filter 'UNOWNED_PRESENT' is applied, and they change the status here
                        // to anything other than 'PRESENT', we remove the special-filter (as it makes no sense)
                        if ((specialFilter === 'UNOWNED_PRESENT' || specialFilter === 'NOBOOK_PRESENT') && e.target.value !== 'PRESENT') {
                            setSpecialFilter(null);
                        }
                    }}
                    notched={true}
                    {...selCommonProps}
                >
                    <MenuItem value="" sx={{color: 'text.disabled'}}>- Status -</MenuItem>
                    {
                        allStatuses.map(s => <MenuItem key={s.status} value={s.status}>{s.status_name}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <FormControl {...fcCommonProps} sx={{ ...fcCommonSx, minWidth: 80 }}>
                <InputLabel id="action-type-filter-label" shrink={true}>Last Action</InputLabel>
                <Select
                    labelId="action-type-filter-label"
                    id="action-type-filter"
                    multiple
                    value={lastActionTypeIds ?? []}
                    label="Last Action"
                    onChange={onActionTypeFilterChange}
                    input={<OutlinedInput label="Action Type" />}
                    notched={true}
                    renderValue={(selected) => {
                        // console.log(selected);
                        if (!selected || selected?.length === 0) return '- Any -';
                        if (selected.length === 1) {
                            if (selected[0] === 0) return '- Any -';
                            return allActionTypes.find(a => a.id === selected[0])?.label;
                        }

                        // Just display the number of selected actions
                        return `${selected.length} selected`;

                        //return selected.map(at => allActionTypes.find(a => a.id === at)?.label).join(', ');
                    }}
                    displayEmpty={true}
                    sx={{fontSize: 'inherit'}}
                >
                    <MenuItem value={0} key={'all'}>
                        <Checkbox checked={!lastActionTypeIds || lastActionTypeIds.length === 0 ||
                            (lastActionTypeIds.length === 1 && lastActionTypeIds[0] === 0)} />
                        <ListItemText primary={'- All -'} />
                    </MenuItem>
                    {
                        allActionTypes.map((vt) => (
                            <MenuItem key={vt.id} value={vt.id}>
                                <Checkbox checked={lastActionTypeIds?.indexOf(vt.id) > -1} />
                                <ListItemText primary={vt.label} />
                            </MenuItem>))
                    }
                </Select>
            </FormControl>

            {hasFilterApplied &&
                <Button color="warning" size="small" sx={{ mt: 1 }}
                        startIcon={<FilterAltOffIcon />}
                        title="Remove all filters/Show all records"
                        onClick={() => clearFilters()}
                >Clear Filters</Button>
            }

            <FileInput
                buttonProps={{
                    size: "small", title: 'Upload Shipping List File', variant: 'text', color: 'primary',
                    loading: unplugsUploadRunning,
                    loadingPosition: 'start',
                    disabled: false,
                    startIcon: <UploadFileIcon />
                }}
                label="Upload Unplugs..."
                onChange={onUploadUnplugs}
                accept=".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                sx={{ mt: 1, ml: 'auto' }}
            />

            <FormControl variant="outlined" size="small" sx={{ mt: 1 }}>
                <Button aria-label="Download" size="small" startIcon={<FileDownloadIcon />}
                        sx={{marginLeft: 'auto'}}
                        onClick={downloadReport}
                >Download</Button>
            </FormControl>
            <GridToolbarColumnsButton sx={{ml: 1, mt: 1}} ref={setColumnsButtonEl} />
        </GridToolbarContainer>
    )
}
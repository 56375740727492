import React from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../common/db";
import {
    DataGrid, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarContainer, useGridApiContext
} from '@mui/x-data-grid';
import {
    formatReefer, isReeferValid, LUXON_MYSQL_DATETIME_FMT, PORTS, prettyDateTime, setTitle
} from "../../common/shared";
import axios from "axios";
import {
    Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton,
    InputLabel, MenuItem, Select, Stack, Switch, TextField, Tooltip
} from "@mui/material";
import * as PropTypes from "prop-types";
import {useSnackbar} from "notistack";
import {AuthContext} from "../../contexts/AuthProvider";
import {DateTime} from "luxon";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DeleteIcon from '@mui/icons-material/Delete';
import FileInput from "../../components/FileInput";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {v4 as uuidv4} from 'uuid';
import EditIcon from '@mui/icons-material/Edit';
import {createBrowserHistory} from "history";

const importExport = {
    'IMP': 'Import',
    'EXP': 'Export',
};


/**
 * Parses a date string and returns a formatted date if valid.
 *
 * @param {string} val - The date string to parse.
 * @param {boolean} [returnDate=false] - If true, returns the parsed DateTime object instead of a formatted date string.
 * @returns {DateTime|string} - A formatted date string or a DateTime object if returnDate is true.
 */
function smartParseDate(val, returnDate = false) {
    let dt = DateTime.fromISO(val);
    if (dt.invalid) {
        dt = DateTime.fromSQL(val);
        if (dt.invalid) {
            //  'D' here is a locale-aware date.
            dt = DateTime.fromFormat(val, 'D', {locale: 'en-GB'})
        }
    }

    // Manually handle the date if it's still invalid here
    if (dt.invalid) {
        const match = val.match(/^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/);
        if (match) {
            let [, d, m, y] = match;
            // Make them all ints
            d = parseInt(d);
            m = parseInt(m);
            y = parseInt(y);

            //  If the year is 2 digits, we need to make it 4 digits
            if (y < 100) {
                y += 2000;
            }
            // if the month > 12, swap it with the day
            if (m > 12) {
                [m, d] = [d, m];
            }

            dt = DateTime.fromObject({year: y, month: m, day: d});
        }
    }

    if (!dt.invalid) {
        val = dt.toSQLDate();
    }

    if (returnDate) {
        return dt;
    }

    return val;
}

export default function Order() {
    const auth = React.useContext(AuthContext);
    const history = createBrowserHistory();
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogRecord, setDialogRecord] = React.useState(null);
    const [orders, setOrders] = React.useState([]);
    const [port, setPort] = React.useState(history.location.state?.port || auth.getDefaultPort() || '');
    const [customerId, setCustomerId] = React.useState(history.location.state?.customerId || null);
    const [depotId, setDepotId] = React.useState(history.location.state?.depotId || null);  // just terminals though
    const [invoicedStatus, setInvoicedStatus] = React.useState(history.location.state?.invoicedStatus || 'N');
    const [impExp, setImpExp] = React.useState(history.location.state?.impExp || '');
    const ports = useLiveQuery(
        () => db.Port.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.code]: cur.name}), {'': '- All Ports -'}))
    , [], {'': '- All Ports-', ...PORTS});
    const users = useLiveQuery(() => db.User.toArray(), [], []);
    const depots = useLiveQuery(() => db.Depot.toArray(), [], []);


    React.useEffect(() => {
        setTitle('Shipping Lists');
    }, []);

    // When filters change, store them in the history
    React.useEffect(() => {
        try {
            history.replace(null, {
                ...history.location.state,
                port,
                customerId,
                depotId,
                invoicedStatus,
                impExp,
            });
        }
        catch (e) {
            console.log(e);
        }
    }, [customerId, depotId, history, invoicedStatus, port, impExp]);

    const refreshOrders = React.useCallback( () => {
        let params = {};
        if (port) {
            params['portCode'] = port;
        }
        if (customerId) {
            params['customer_id'] = customerId;
        }
        if (depotId) {
            params['terminal_id'] = depotId;
        }
        if (invoicedStatus) {
            params['invoicedStatus'] = invoicedStatus;
        }
        if (impExp) {
            params['import_export'] = impExp;
        }
        axios.get('/admin/order', { params: params })
            .then(
                (result) => {
                    // console.log(result);
                    setIsLoaded(true);
                    if (result.data.error) {
                        setError(result.data);
                    } else {
                        setOrders(result.data.result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.response.data || error);
                }
            )
    }, [port, customerId, depotId, invoicedStatus, impExp]);

    const editRow = (record) => {
        setDialogRecord(record);
        setDialogOpen(true);
    }

    const addNewRow = () => {
        editRow({
            id: null,
            customer_id: customerId,
            terminal_id: depotId,
            created_by: parseInt(auth.user.id),
            created_at: DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT), // .toSQL({includeZone: false, includeOffset: false}),
            import_export: impExp !== '' ? impExp : 'IMP',
            port_code: port,
            booking_ref: '',
            completed: 0,
        })
    }

    const onEditCancel = () => {
        setDialogOpen(false);
        setDialogRecord(null);
    }

    const onEditSave = (record) => {
        if (record) {
            setDialogRecord(null);
            setDialogOpen(false);
            refreshOrders(); //  easiest way to get the new data (but not really necessary, we could just modify orders too)
        }
    }

    const onEditDelete = (record) => {
        // We actually get the full deleted record back here, so we could pop up a toast or something if needs be.
        setDialogRecord(null);
        setDialogOpen(false);
        setOrders((prevRows) => prevRows.filter((row) => row.id !== record.id));
    }

    const onRowClick = (params, event, details) => {
        console.log(params, event, details);
        // setSelected(params.row);
        editRow(params.row);
    }

    const onPortChange = (event) => {
        console.log(event);
        setPort(event.target.value);
        setDepotId(null);
    }

    const onCustomerChange = (event) => {
        // console.log(event);
        setCustomerId(event.target.value ?? null);
    }

    const onDepotChange = (event) => {
        // console.log(event);
        setDepotId(event.target.value ?? null);
    }

    const onInvoicedStatusChange = (event) => {
        // console.log(event);
        setInvoicedStatus(event.target.value ?? '');
    }

    const onImpExpChange = (event) => {
        // console.log(event);
        setImpExp(event.target.value ?? '');
    }

    React.useEffect(() => {
        refreshOrders();
    }, [refreshOrders]);

    const COLS_KEY = 'adm/Order/Order/cols';
    const [colVis, setColVis] = React.useState(() => {
        const c = localStorage.getItem(COLS_KEY);
        if (c) return JSON.parse(c);
        return {
            created_at: false,
            created_by: false,
        }
    })

    const onColVisChange = (newVis) => {
        console.log(newVis);
        setColVis(newVis);
        localStorage.setItem(COLS_KEY, JSON.stringify(newVis));
    }

    const columns = React.useMemo(() => [
            {
                field: 'id',
                headerName: 'ID',
                width: 70, type: 'number',
                valueGetter: ({ value }) => parseInt(value)
            },
            {
                field: 'customer_name',
                headerName: 'Customer',
                flex: 1,
                valueGetter: ({row}) => row.customer?.name ?? '',
            },
            {
                field: 'booking_ref',
                headerName: 'Ship Ref',  // DB 2023-01-16: Renamed to avoid confusion with new booking_ref field in oc.
                minWidth: 100,
                flex: 1,
            },
            {
                field: 'port', headerName: 'Port',
                width: 130,
                valueGetter: ({ row }) => ports?.[row.port_code] || ' - '
            },
            {
                field: 'terminal_id',
                headerName: 'Terminal',
                width: 130,
                valueGetter: ({ row }) => depots.find(d => d.id === row.terminal_id)?.name || ' - '
            },
            {
                field: 'import_export', headerName: 'Import/Export',
                width: 110,
                valueFormatter: ({ value }) => importExport?.[value] || ' - '
            },
            {
                field: 'due_date',
                headerName: 'Date',
                type: 'date',
                width: 90,
                valueGetter: ({ value }) => {
                    if (!value) return null;
                    try {
                        return DateTime.fromSQL(value).toJSDate();
                    }
                    catch (e) {
                        console.error(e);
                        return null;
                    }
                },
                //valueFormatter: ({value}) => prettyDate(value),
                valueFormatter: ({ value }) => {
                    if (!value) return null;
                    try {
                        return DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT);
                    }
                    catch (e) {
                        console.error(e);
                        return null;
                    }
                }
            },
            {
                field: 'numContainers', headerName: 'Containers',
                width: 100,
                type: 'number',
                valueGetter: ({ row }) => row.numContainers ?? 0
            },
            {
                field: 'numDispatched', headerName: 'Dispatched',
                width: 100,
                type: 'number',
                valueGetter: ({ row }) => row.numDispatched ?? 0
            },
            {
                field: 'numNotDispatched', headerName: 'Not-Dispatched',
                width: 120,
                type: 'number',
                valueGetter: ({ row }) => row.numNotDispatched ?? 0
            },
            {
                field: 'numPresent', headerName: 'In-Port',
                width: 100,
                type: 'number',
                valueGetter: ({ row }) => row.numPresent ?? 0
            },
            {
                field: 'numNotInvoiced', headerName: 'Not Invoiced',
                width: 100,
                type: 'number',
                valueGetter: ({ row }) => row.numNotInvoiced ?? 0
            },
            {
                field: 'invoiced', headerName: 'Invoiced',
                width: 100,
                valueGetter: ({ row }) => row.numContainers > 0 ? (row.numNotInvoiced > 0 ? 'No' : 'Yes') : '-'
            },
            {
                field: 'created_at', headerName: 'Created On',
                minWidth: 110,
                flex: 1,
                valueGetter: (params) => {
                    if (!params.value) return null;
                    //  Stored as a UTC mysql string, but we need a date.
                    return prettyDateTime(params.value);
                    //return DateTime.fromSQL(params.value, {zone: 'utc'}).toLocal().toJSDate();
                },
            },
            {
                field: 'created_by', headerName: 'Created By',
                minWidth: 100,
                flex: 1,
                valueGetter: ({ value }) => {
                    if (!value) return null;
                    if (!users) return '';
                    const u = users.find(u => u.id === value);
                    if (u) return `${u.firstname} ${u.surname}`;
                    return '???';
                }
            },
            {
                field: 'completed', headerName: 'Completed',
                width: 100,
                valueGetter: ({ row }) => row.completed ? 'Yes' : 'No',
                cellClassName: ({row}) => `completed-${row.completed ? 'yes' : 'no'}`,
            }
        ], [ports, users, depots],
    );

    if (error) {
        return (<Alert severity="error">{error.message || error}</Alert> )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid columns={columns} rows={orders}
                          loading={!isLoaded}
                          sx={{
                              bgcolor: '#ffffff',
                              borderRadius: 0,
                              "& .MuiDataGrid-cell": {
                                  cursor: "pointer",
                              },
                              '& .MuiDataGrid-cell:focus': {
                                  outline: "none",
                              },
                              '& .completed-yes': {
                                  backgroundColor: '#7fff00 !important',
                              },
                              '& .completed-no': {
                                  backgroundColor: '#ff6347 !important',
                              },
                          }}
                          components={{
                              Toolbar: OrdersToolbar
                          }}
                          componentsProps={{
                              toolbar: {
                                  port: port,
                                  customerId: customerId ?? null,
                                  depotId: depotId ?? null,
                                  invoicedStatus: invoicedStatus,
                                  impExp: impExp,
                                  ports: ports,
                                  onPortChange: onPortChange,
                                  onCustomerChange: onCustomerChange,
                                  onDepotChange: onDepotChange,
                                  onInvoicedStatusChange: onInvoicedStatusChange,
                                  onImpExpChange: onImpExpChange,
                                  addOrder: addNewRow
                              }
                          }}
                          hideFooterSelectedRowCount={true}
                          onRowClick={onRowClick}
                          columnVisibilityModel={colVis}
                          onColumnVisibilityModelChange={onColVisChange}
                          initialState={{
                              sorting: {
                                  sortModel: [{field: 'id', sort: 'desc'}]
                              }
                          }}
                />
            </Box>
            {
                dialogOpen &&
                <EditDialog record={dialogRecord} onCancel={onEditCancel}
                            onSave={onEditSave} onDelete={onEditDelete}
                            ports={ports}
                />
            }
        </Box>
    )
}

function OrdersToolbar(props) {

    const customers = useLiveQuery(() => db.Customer.orderBy('name').toArray(), []);
    const terminalsInPort = useLiveQuery(() => db.Depot.where('port_code').equals(props.port)
        .filter(d => ['BLANK', 'TERMINAL'].includes(d.entry_type)).sortBy('name'), [props.port]);

    return (
        <GridToolbarContainer>
            <FormControl size={"small"} sx={{minWidth: "150px", ml: 1, mt: 1}}>
                <InputLabel id="port-id-label">Port</InputLabel>
                <Select
                    labelId="port-id-label"
                    id="port-id"
                    value={props.port}
                    label="Port"
                    onChange={props.onPortChange}
                >
                    {
                        Object.entries(props.ports).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
                    }
                </Select>
            </FormControl>
            {terminalsInPort && (
                <FormControl size={"small"} sx={{minWidth: "150px", ml: 1, mt: 1}}>
                    <InputLabel id="depot-id-label">Terminal</InputLabel>
                    <Select
                        labelId="depot-id-label"
                        id="depot-id"
                        value={props.depotId ?? 0}
                        label="Terminal"
                        onChange={props.onDepotChange}
                    >
                        <MenuItem value={0}>- All -</MenuItem>
                        {
                            terminalsInPort?.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            )}
            {customers && (
                <FormControl size={"small"} sx={{minWidth: "150px", ml: 1, mt: 1}}>
                    <InputLabel id="customer-id-label">Customer</InputLabel>
                    <Select
                        labelId="customer-id-label"
                        id="customer-id"
                        value={props.customerId ?? 0}
                        label="Customer"
                        onChange={props.onCustomerChange}
                    >
                        <MenuItem value={0}>- All -</MenuItem>
                        {
                            customers?.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            )}
            <FormControl size={"small"} sx={{minWidth: "150px", ml: 1, mt: 1}}>
                <InputLabel id="invoicedStatus-label">Invoiced</InputLabel>
                <Select
                    labelId="invoicedStatus-label"
                    id="invoicedStatus"
                    value={props.invoicedStatus ?? ''}
                    label="Invoiced"
                    onChange={props.onInvoicedStatusChange}
                >
                    <MenuItem key="dontCare" value={''}>- N/A -</MenuItem>
                    <MenuItem key='Y' value={'Y'}>Yes</MenuItem>
                    <MenuItem key='N' value={'N'}>No</MenuItem>
                </Select>
            </FormControl>
            <FormControl size={"small"} sx={{minWidth: "150px", ml: 1, mt: 1}}>
                <InputLabel id="impExp-label">Import/Export</InputLabel>
                <Select
                    labelId="impExp-label"
                    id="impExp"
                    value={props.impExp ?? ''}
                    label="Import/Export"
                    onChange={props.onImpExpChange}
                >
                    <MenuItem key="dontCare" value={''}>- All -</MenuItem>
                    <MenuItem key='IMP' value={'IMP'}>Import</MenuItem>
                    <MenuItem key='EXP' value={'EXP'}>Export</MenuItem>
                </Select>
            </FormControl>

            {/*<GridToolbarFilterButton />*/}
            {/*<GridToolbarDensitySelector />*/}
            {/*<GridToolbarExport />*/}
            <GridToolbarColumnsButton sx={{ml:'auto'}} />
            <Button aria-label="Add Shipping List" size="small" startIcon={<FormatListNumberedIcon />}
                    // sx={{marginLeft: 'auto'}}
                    onClick={props.addOrder}> New Shipping List</Button>
        </GridToolbarContainer>
    );
}

OrdersToolbar.propTypes = {
    port: PropTypes.string.isRequired,
    customerId: PropTypes.number,
    depotId: PropTypes.number,
    ports: PropTypes.object.isRequired,
    invoicedStatus: PropTypes.string,
    onPortChange: PropTypes.func.isRequired,
    onCustomerChange: PropTypes.func.isRequired,
    onDepotChange: PropTypes.func.isRequired,
    onInvoicedStatusChange: PropTypes.func.isRequired,
    onImpExpChange: PropTypes.func.isRequired,
    addOrder: PropTypes.func.isRequired,
}

const FILE_TEMPLATES = {
    // This is lankel's "internal" template. It must match the column headers in the file downloaded from
    // \Admin\Controllers\OrderController::containersFileAction
    LANKEL: {
        'Unit': 'reefer_num',
        'Location': 'depot_id',
        'Set Point': 'set_temp',
        'Set Humidity': 'set_humidity',
        'Cargo': 'cargo_description',
        'Ship': 'ship',
        'Vessel Date': 'sail_date',
        'Booking Ref': 'booking_ref',
        // 'Voyage Number': 'voyage_number',
        'Notes': 'customer_note'
    },
    SAMSKIP: {
        "Unit Number": 'reefer_num',
        // Changed per https://tickety.pentagoncomputers.com/ticket/495
        "CustomerNote": 'booking_ref',
        "ReleaseNo": 'release_no',
        //"UnitTypeId": null,
        "Temperature Control 'setting'": 'set_temp',
        "Ship": 'ship',
        "SailDate": 'sail_date',
        // Removed per https://tickety.pentagoncomputers.com/ticket/495
        //"Voyage Number": 'booking_ref',  // 'voyage_number',  Changed per lankel/lankel#34
        //"Pol": null,
        //"Pod": null,
        "Cargo Descriptions (From Booking)": 'cargo_description'
    },
    HAPAG_LLOYD: {
        //"Status": null,
        //"ST": null,
        //"No Move": null,
        "Shipment": 'booking_ref',
        "Remarks": 'customer_note',
        //"MTD Number": null,
        //"MTD DC": null,
        //"Seq": null,
        "Container": 'reefer_num',
        //"Type": null,
        //"Weight": null,
        //"VGM": null,
        //"Wrong Temp [C]": null,
        "Temp [C]": 'set_temp',
        "Cargo Description": 'cargo_description',
        //"MR Party": null,
        //"MR Party_2": null,
        //"CSB User": null,
        //"Status_2": null,
        //"Date": null,
        //"Loc": null,
        //"Place": null,
        //"Wrong Dwell Time ": null,
        //"Dwell Time": null,
        //"Geo. From": null,
        //"Geo. From_2": null,
        //"Geo. From_3": null,
        //"Geo. From_4": null,
        //"Geo. From_5": null,
        //"Pre.POL": null,
        //"Inb. DBVoy": null,
        //"Inb. Vessel": null,
        //"Date_2": null,
        //"Time": null,
        //"Inbound Terminal": null,
        //"Inbound Terminal_2": null,
        //"POL": null,
        //"Out DPvoy": null,
        "Out Vessel": 'ship',
        "Date_3": 'sail_date',
        //"Time_2": null,
        //"Outb. Load Terminal": null,
        //"Outb. Load Terminal_2": null,
        //"PoD": null,
        //"Terminal": null,
        //"Terminal_2": null,
        //"Workorder": null,
        //"Temp Sep": null,
        //"Subcontr.": null,
        //"Subcontr._2": null,
        //"Sent": null,
        //"Final Port": null,
        //"HL rolls": null,
    },
    EUCON: {
        'Vessel' : 'ship',
        // 'Voyage' : '2242204',
        'B/L number' : 'booking_ref', // first version
        'Unit/Container' : 'reefer_num',
        // 'Date Delivery' : '6/4/2024',
        // 'Type' : '45RH',
        // 'Service' : 'HH',
        // 'Type booked' : '45RH',
        // 'Cargotype' : 'RR',
        'Reefer temp' : 'set_temp',
        // 'Reefer temp-2' : 6,
        'Name of goods' : 'cargo_description',
    },
    EUCON2: { // this is identical to above, but has 'Bkno' instead of 'B/L number' for the booking ref
        'Vessel' : 'ship',
        // 'Voyage' : '2242204',
        'Bkno' : 'booking_ref', // second version
        'Unit/Container' : 'reefer_num',
        // 'Date Delivery' : '6/4/2024',
        // 'Type' : '45RH',
        // 'Service' : 'HH',
        // 'Type booked' : '45RH',
        // 'Cargotype' : 'RR',
        'Reefer temp' : 'set_temp',
        // 'Reefer temp-2' : 6,
        'Name of goods' : 'cargo_description',
    }
}

/**
 * Get the mappings appropriate for an upload with columns `cols`.
 * The mappings returned is an object where every key is an upload (file) column, and every value is our local
 * equivalent field.
 *
 * @param cols
 * @returns {null|Object}
 */
function getTemplateMapping(cols) {
    // First search for a template that has all the columns we need
    for (const [, mappings] of Object.entries(FILE_TEMPLATES)) {
        const neededCols = Object.keys(mappings);
        if (neededCols.every(c => cols.includes(c))) return mappings;
    }
    // Failing that, identify which template has the most columns, and use that
    let maxCols = 0;
    let maxColsTemplate = null;
    for (const [, mappings] of Object.entries(FILE_TEMPLATES)) {
        const neededCols = Object.keys(mappings);
        // Find the number of entries in neededCols that are also in cols
        const intersection = neededCols.filter(c => cols.includes(c));
        if (intersection.length > maxCols) {
            // Find the key with value 'reefer_num' in mappings (we need this one)
            const reeferNumKey = Object.keys(mappings).find(k => mappings[k] === 'reefer_num');
            // If the intersection doesn't include reeferNumKey, then we can't use this template
            if (!intersection.includes(reeferNumKey)) continue;
            maxCols = intersection.length;
            maxColsTemplate = mappings;
        }
    }
    if (maxColsTemplate) return maxColsTemplate;
    return null;
}

function OCToolbar(props) {
    //const { enqueueSnackbar } = useSnackbar();
    const gridApiRef = useGridApiContext();
    const [ addContEntries, setAddContEntries ] = React.useState([{id: '-1', label: '- Add Unlisted Container -'}]);
    const [uploadRunning, setUploadRunning] = React.useState(false);
    React.useEffect(() => {
        //  The menu starts with the 'Add Unlisted...' entry, then listing all unowned ocs
        let menu = [{id: '-1', label: '- Add Unlisted Container -'}];
        // console.log(props);
        const usedUuids = (props.order?.ocs || []).map(c => c.uuid);
        props.unownedOCs.filter(c => !usedUuids.includes(c.uuid)).forEach((oc) => menu.push({
            id: oc.uuid,
            label: `${formatReefer(oc.reefer_num)} (${props.depots.find(d => d.id === oc.depot_id)?.name ?? '???'})`
        }));
        // menu.push({id: '-1', label: '- Add Unlisted Container -'});
        setAddContEntries(menu);
    }, [props]);

    const onAddContainerChange = (event) => {
        const selectedUuid = event.target.value;  // or '-1' for adding a new container
        const newOc = props.onAddContainer(selectedUuid);
        setTimeout(() => {
            // gridApiRef.current.startRowEditMode({uuid: newOc.uuid, id: newOc.uuid});
            //  This doesn't work (doesn't do anything)
            setTimeout(() => gridApiRef.current.setCellFocus({id: newOc.uuid, field: 'reefer_num'}));
            // props.setRowModesModel((oldModel) => {
            //     console.log(oldModel);
            //     return {
            //         ...oldModel,
            //         [newOc.uuid]: { mode: GridRowModes.Edit, fieldToFocus: 'reefer_num'}
            //     };
            // })
        });
    }

    const onFileUpload = async (file, params) => {
        console.log(file, params);
        setUploadRunning(true);
        props.onFileUpload(file, params)
            .then((result) => {
                return result;
            })
            .finally(() => {
                setUploadRunning(false);
            });
    }

    return (
        <GridToolbarContainer>
            <FormControl size="small" variant="standard" sx={{minWidth: "150px"}}>
                <InputLabel id="add-container-label">Add Container</InputLabel>
                <Select
                    labelId="add-container-label"
                    id="add-container-id"
                    label="Add Container"
                    onChange={onAddContainerChange}
                    value=''
                >
                    {addContEntries.map((entry) =>
                        <MenuItem key={entry.id} value={entry.id}>{entry.label}</MenuItem>)}
                </Select>
            </FormControl>
            <Button sx={{ml: 'auto' }} aria-label="Download" size="small"
                    startIcon={<FileDownloadIcon />}
                    title='Download table of containers in this order'
                    onClick={props.onFileDownload}> Download</Button>
            <GridToolbarColumnsButton />
            <FileInput
                buttonProps={{
                    size: "small", title: 'Upload Shipping List File', variant: 'text', color: 'primary',
                    loading: uploadRunning,
                    loadingPosition: 'start',
                    disabled: !props.order.terminal_id,
                    startIcon: <UploadFileIcon />
                }}
                label="Add from File..."
                onChange={onFileUpload}
                accept=".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
        </GridToolbarContainer>
    )
}

OCToolbar.propTypes = {
    depots: PropTypes.array.isRequired,
    unownedOCs: PropTypes.array,
    order: PropTypes.object.isRequired,
    onAddContainer: PropTypes.func.isRequired,
    // setRowModesModel: PropTypes.func.isRequired,
    onFileUpload: PropTypes.func.isRequired,
    onFileDownload: PropTypes.func.isRequired,
}
//
// const useStyles = makeStyles((theme) => ({
//     actionRoot: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'stretch',
//         justifyContent: 'center',
//         paddingBottom: theme.spacing(1),
//     },
// }));

//
// function KeepRemoveDeleteSnack(props) {
//     const classes = useStyles();
//     const { msg, isHtml, handleKeep, handleRemove, handleDelete } = props;
//
//     const message = isHtml ? <div dangerouslySetInnerHTML={{__html: msg}} /> : msg;
//
//     return (
//         <SnackbarContent
//             message={message}
//             action={
//                 <div className={classes.actionRoot}>
//                     <Button color="inherit" size="small" onClick={handleKeep}>Keep</Button>
//                     <Button color="inherit" size="small" onClick={handleRemove}>Remove</Button>
//                     <Button color="inherit" size="small" onClick={handleDelete}>Delete</Button>
//                 </div>
//             }
//         />
//     );
// }
// KeepRemoveDeleteSnack.propTypes = {
//     msg: PropTypes.string.isRequired,
//     isHtml: PropTypes.bool,
//     handleKeep: PropTypes.func.isRequired,
//     handleRemove: PropTypes.func.isRequired,
//     handleDelete: PropTypes.func.isRequired,
// }

function EditDialog(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [waitingSnack, setWaitingSnack] = React.useState(null);
    // const [rowModesModel, setRowModesModel] = React.useState({});
    //const gridApiRef = useGridApiRef();
    const [record, setRecord] = React.useState({
        ...props.record,
        port_code: props.record.port_code || 'IEDUB',
        ocs: props.record.ocs ?? []
    });
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(null);
    const isNew = !record.id;
    const customers = useLiveQuery(() => db.Customer.orderBy('name').toArray(),
        [], []
    );
    const depots = useLiveQuery(() => db.Depot.where({port_code: record.port_code})
            .sortBy('name'),
        [record.port_code], []
    );
    const allStatuses = useLiveQuery(() => db.ContainerStatus.toArray(), [], []);
    React.useEffect(() => {
        if (record.id) {
            axios.get('/admin/order/get', {params: {id: record.id}})
                .then(
                    (result) => {
                        console.log(result);
                        if (result.data.error) {
                            setError(result.data);
                        } else {
                            //  Overwrite the record we have with the one we received (which is more reliable)
                            setRecord((prev) => ({
                                ...prev,
                                ...result.data.order
                            }));
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
        }
    }, [record.id])

    //  Unowned containers: containers in the dock which don't currently have an order.
    //  It'd be nice if we could just get these from dexie, but as it requires filtering the OrderContainer for
    //  entries where order_id is null (which IndexedDb, and hence dexie, can't do), we send this off to the server
    //  instead.
    const [unownedOCs, setUnownedOCs] = React.useState([]);
    const refreshUnowned = React.useCallback(() => {
        if (record.port_code) {
            axios.get('/admin/order/unownedContainers', {params: {port_code: record.port_code}})
                .then(
                    (result) => {
                        console.log(result);
                        if (result.data.error) {
                            setError(result.data);
                        } else {
                            setUnownedOCs(result.data.result);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
        }
    }, [record.port_code]);
    React.useEffect(() => {
        refreshUnowned();

        // Set up a timer to refresh the unowned containers every 2 minutes
        const timer = setInterval(() => {
            refreshUnowned();
        }, 2 * 60 * 1000);

        return () => {
            clearInterval(timer);
        }
    }, [refreshUnowned]);

    const handleClose = () => {
        if (waitingSnack) closeSnackbar(waitingSnack);
        props.onCancel();
    }

    const handleSave = () => {
        if (record) {
            axios.post('/admin/order/save', {order: record})
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Shipping List Saved', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.onSave(result.data.order);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const handleDelete = () => {

        if (record.numDispatched && record.numDispatched > 0) {
            if (!window.confirm('This order has containers which have been dispatched. Are you sure you want to delete it?')) {
                return;
            }
        }

        axios.post('/admin/order/delete', record)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        setError(result.data);
                    }
                    else {
                        setError(false);
                        enqueueSnackbar(result.data.message || 'Shipping List Deleted', {
                            variant: result.data.warning ? 'warning': 'success'
                        })
                        props.onDelete(record);
                    }
                },
                (error) => {
                    setError(error.response.data || error);
                }
            )
            .finally(() => {
                setSaving(false);
            });
    }

    const onFormChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    /**
     * This takes an array of ocs, and attempts to delete them permanently from the database.
     * @param ocs
     */
    const attemptDeleteOcs = (ocs) => {
        axios.post('/admin/orderContainer/attemptBulkDelete', {ocs: ocs})
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        enqueueSnackbar(result.data.message || 'Error deleting containers', {
                            variant: 'error',
                            autoHideDuration: 30000
                        });
                    }
                    else {
                        // `outcome` is an object, keyed on uuid, with values which are strings, one entry for
                        // each of the ocs we sent in.
                        // Values can be:
                        //  'MISSING' - the oc was not found in the database
                        //  'INVOICED' - the oc has been invoiced, and cannot be deleted
                        //  'HAS_ACTIONS' - the oc has actions associated with it, and cannot be deleted
                        //  'DELETED' - the oc was successfully deleted
                        //  'FAILED' - some other kind of error occurred during deletion.
                        const outcomes = result.data.outcome;
                        // Reorganise them by outcome, and lookup their reefer numbers in the process
                        const byOutcome = {};
                        for (const [uuid, outcome] of Object.entries(outcomes)) {
                            if (!byOutcome[outcome]) byOutcome[outcome] = [];
                            const rn = ocs.find((oc) => oc.uuid === uuid)?.reefer_num || 'Unknown';
                            byOutcome[outcome].push(rn);
                        }
                        let useVariant = 'success';
                        if (byOutcome['FAILED']?.length > 0) useVariant = 'error';
                        else {
                            if (byOutcome['INVOICED']?.length > 0) useVariant = 'warning';
                            if (byOutcome['HAS_ACTIONS']?.length > 0) useVariant = 'warning';
                        }
                        // MISSING and DELETED are considered successful.

                        let successReefers = byOutcome['DELETED'] || [];
                        if (byOutcome['MISSING']?.length > 0) {
                            successReefers = successReefers.concat(byOutcome['MISSING']);
                        }
                        let hasActionsReefers = byOutcome['HAS_ACTIONS'] || [];
                        let invoicedReefers = byOutcome['INVOICED'] || [];
                        let failedReefers = byOutcome['FAILED'] || [];

                        let msg = 'Deletion Result:';
                        if (successReefers.length > 0) {
                            msg += `<br> &#x2022; ${successReefers.join(', ')} successfully deleted. `;
                        }
                        if (hasActionsReefers.length > 0) {
                            msg += `<br> &#x2022; ${hasActionsReefers.join(', ')} have actions associated with them and cannot be deleted (but were removed from the shipping list). `;
                        }
                        if (invoicedReefers.length > 0) {
                            msg += `<br> &#x2022; ${invoicedReefers.join(', ')} have been invoiced and cannot be deleted (but were removed from the shipping list). `;
                        }
                        if (failedReefers.length > 0) {
                            msg += `<br> &#x2022; ${failedReefers.join(', ')} failed to delete. `;
                        }

                        enqueueSnackbar(<span dangerouslySetInnerHTML={{__html: msg}} /> , {
                            variant: useVariant,
                            autoHideDuration: useVariant === 'error' ? 30000: useVariant === 'warning' ? 15000 : 5000
                        });
                    }
                },
                (error) => {
                    enqueueSnackbar(error.response.data.message || 'Error deleting containers', {
                        variant: 'error',
                        autoHideDuration: 30000
                    });
                }
            );
    };

    /**
     * When the port changes, we also need to blank the depot, so this gets its own handler.
     * @param event
     */
    const onPortChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            port_code: event.target.value,
            terminal_id: null,
        }));
    }

    const onCompletedChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            completed: event.target.checked ? 1 : 0,
        }));
    }

    /**
     * Get the default depot (for a newly added container which doesn't have one).
     * ~~This defaults to the depot used in the last orderContainer entry, or the 'blank' depot in the port if there~~
     * ~~is none.~~
     *
     * Updated 2023-02-10: This now defaults to the terminal of order (for imports), or the 'blank' entry (for exports).
     *
     * @returns {number|undefined}
     */
    const getDefaultDepot = () => {
        // old way...
        // if (record.ocs?.length) {
        //     //  Step backwards through the ocs, and return the first one we find with a depot
        //     //  (probably the most recently entered one).
        //     for (let i=record.ocs.length -1; i >= 0; i--) {
        //         if (record.ocs[i].depot_id) return record.ocs[i].depot_id;
        //     }
        // }

        if (record.import_export === 'IMP' && record.terminal_id) {
            return record.terminal_id;
        }

        // Failing the above, return the first 'blank' depot.
        const blankDepot = depots.find((d) => d.entry_type === 'BLANK');
        if (blankDepot) return blankDepot.id;
        //  Failing that, just return the first depot that we know about
        return depots?.[0]?.id;
    }

    const onAddContainer = (contUuid) => {
        if (contUuid === '-1') {
            //  Add a blank container
            const newOc = {
                uuid: uuidv4(),
                order_id: record.id,
                reefer_num: '',
                depot_id: getDefaultDepot(),
                cargo_description: null,
                set_temp: null,
                set_humidity: null,
                status: 'DUE',
                in_at: null,
                out_at: null,
                due_date: null,
                customer_note: null,
                release_no: null,
                ship: null,
                sail_date: null,
                voyage_number: null,
                booking_ref: null,
                ie: record.import_export?.[0],
                is_flagged: 0,
                _isNew: true,   //  internal flag that tells us we can delete this row without confirmation.
            }
            const oldOcs = record.ocs || [];
            const newOcs = [...oldOcs, newOc];
            setRecord((prev) => ({
                ...prev,
                ocs: newOcs
            }));
            return newOc;
        }
        else {
            //  Otherwise it's an unclaimed container
            const newOc = unownedOCs.filter(oc => oc.uuid === contUuid)?.[0];
            if (newOc) {
                newOc.order_id = record.id; //  ensure that it now has the correct order_id
                // If the added oc doesn't have an 'ie' field, and the order has an 'import_export' field, then
                // copy the first letter of that into the oc.
                if (!newOc.ie && record.import_export) {
                    newOc.ie = record.import_export[0];
                }
                const oldOcs = record.ocs || [];
                const newOcs = [...oldOcs, newOc];
                setRecord((prev) => ({
                    ...prev,
                    ocs: newOcs
                }));
                return newOc;
            }
        }
        //  Otherwise do nothing
    }

    const onDeleteContainer = React.useCallback(
        (contUuid) => {
            console.log('onDeleteContainer', contUuid);
            const oldOcs = record.ocs || [];
            const newOcs = oldOcs.filter(oc => oc.uuid !== contUuid);
            setRecord((prev) => ({
                ...prev,
                ocs: newOcs
            }));
        },
        [record.ocs]
    );

    const onFileUpload = async (file, params) => {
        let formData = new FormData();
        formData.append("file", file);
        await axios.post('/admin/order/processFile', formData)
            .then(
                (result) => {
                    console.log(result);

                    if (result.data.error) {
                        enqueueSnackbar(result.data.message || 'Upload Failed', {variant: 'warning'});
                    }
                    else {
                        const cols = result.data.columns;
                        const mappings = getTemplateMapping(cols);
                        if (!mappings) {
                            enqueueSnackbar('Unable to identify which template to use.', { variant: 'warning' });
                            return;
                        }

                        const numLinesInFile = result.data.file.length;

                        // `ourOcs` starts as a copy of the existing ocs, or an empty array.
                        // We'll modify this as we go through the file, and then set it back into the record at the end.
                        let ourOcs = [...record.ocs] || [];

                        // `oldReefers` is a set of reefer_nums that were present in the order already.
                        const oldReefers = new Set(ourOcs.map(o => o.reefer_num));
                        // `mergedReefers` is a set of reefer_nums that were present in the upload file, and were in
                        // the order already.
                        let mergedReefers = new Set();
                        // `claimedReefers` is a set of reefer_nums that were claimed from unownedOCs.
                        let claimedReefers = new Set();
                        // `newReefers` is a set of reefer_nums that were in the upload file, but were not already
                        // present in the order, or in unownedOCs.
                        let newReefers = new Set();
                        // `missingReefers` is a set of reefer_nums that were in the order but not in the upload file.
                        let missingReefers = new Set(oldReefers); // start with all, and remove as we find them below.
                        let warnings = []; // this is a list of warnings that we'll display at the end.

                        // We need to know the id of the 'BLANK' depot, as it has special handling
                        const blankDepot = depots.find((d) => d.entry_type === 'BLANK');

                        let ocsIn = [];
                        for (const line of result.data.file) {
                            let ocIn = {
                                uuid: uuidv4(),
                                order_id: record.id,
                                reefer_num: '',
                                depot_id: null,  // we fix this later if it's missing
                                cargo_description: null,
                                set_temp: null,
                                set_humidity: null,
                                status: 'DUE',
                                in_at: null,
                                out_at: null,
                                due_date: null,
                                customer_note: null,
                                release_no: null,
                                ship: null,
                                sail_date: null,
                                voyage_number: null,
                                booking_ref: null,
                                ie: record.import_export?.[0],
                                is_flagged: 0,
                            };
                            for (const [theirs, ours] of Object.entries(mappings)) {
                                let val = line?.[theirs] === undefined ? null : line?.[theirs];  // ocIn[ours];
                                if (val !== null) {
                                    switch (ours) {
                                        case 'reefer_num':
                                            //  just remove whitespace and slashes and make upper
                                            val = val.replace(/[\s/]/g, '').toUpperCase();
                                            break;
                                        case 'depot_id':
                                            if (Number.isInteger(val)) {
                                                //  It's already an integer, no change needed, just check if it's a valid depot_id
                                                if (!depots.find(d => d.id === val)) val = null;
                                            }
                                            else if (typeof val === 'string' && !isNaN(parseInt(val, 10)) && parseInt(val, 10).toString() === val) {
                                                // It's a string that looks like an integer, so convert it to an integer
                                                val = parseInt(val, 10);
                                                if (!depots.find(d => d.id === val)) val = null;
                                            }
                                            else {
                                                //  Here the best we can do is to look up the depot name
                                                val = `${val}`;  //  make sure it's a string  (in case it's a float or something)
                                                const dep = depots.find(d => d.name.toLowerCase() === val.toLowerCase());
                                                val = dep ? dep.id : null;
                                            }
                                            break;
                                        case 'cargo_description':
                                        case 'customer_note':
                                        case 'release_no':
                                        case 'ship':
                                        case 'voyage_number':
                                        case 'booking_ref':
                                        case 'status':
                                        default:
                                            //  Just make sure these are strings, no change otherwise
                                            val = val.toString();
                                            break;
                                        case 'set_temp':
                                        case 'set_humidity':
                                            if (!isNaN(val)) {
                                                //  It's already a number, no change needed.
                                            }
                                            else {
                                                val = val.replace(/[^\d.-]/g, '');  //  remove everything that isn't part of a number
                                                val = parseFloat(val);
                                                if (isNaN(val)) val = null;
                                            }
                                            break;
                                        case 'in_at':
                                        case 'out_at':
                                        case 'due_date':
                                        case 'sail_date':
                                            //  This should be a date or datetime
                                            //  Try the various iso8601 formats first (unlikely, but you never know)
                                            // let dt = DateTime.fromISO(val);
                                            // if (dt.invalid) {
                                            //     dt = DateTime.fromSQL(val);
                                            //     if (dt.invalid) {
                                            //         //  'D' here is a locale-aware date.
                                            //         dt = DateTime.fromFormat(val, 'D', {locale: 'en-GB'})
                                            //     }
                                            // }
                                            // if (!dt.invalid) {
                                            //     val = dt.toSQLDate();
                                            // }
                                            val = smartParseDate(val);
                                            //  Try leaving val alone if the above fails. The datagrid code might
                                            //  be able to handle it (e.g. could be US format).
                                            break;
                                        case 'ie':
                                            // If the first letter, capitalized, is 'I' or 'E', use it, otherwise we
                                            // ignore it.
                                            val = val?.[0]?.toUpperCase();
                                            if (val !== 'I' && val !== 'E') val = null;
                                            break;
                                    }
                                }
                                ocIn[ours] = val;
                            }
                            ocsIn.push(ocIn);

                            // Remove this reefer_num from missingReefers, if it's there.
                            missingReefers.delete(ocIn.reefer_num);
                        }
                        console.log(ocsIn);


                        // Next we iterate through each of these and, if we have them in the order already, or if
                        // they're in unownedOCs, we overwrite that data with the data from the file (as much as
                        // we can).
                        ocsIn = ocsIn.map((ocIn) => {
                            const rn = ocIn.reefer_num;

                            // If it's in the order already, overwrite what the order has.
                            if (oldReefers.has(rn)) {
                                const existingOc = ourOcs.find(o => o.reefer_num === ocIn.reefer_num);
                                console.log(`Already have ${ocIn.reefer_num}, merging it`);
                                mergedReefers.add(ocIn.reefer_num);

                                let ocTemp = {...existingOc}; //  work on a copy.
                                for (const [k, v] of Object.entries(ocIn)) {
                                    if (['reefer_num', 'uuid', 'status', 'is_flagged'].includes(k)) continue;
                                    // Don't copy the _isNew flag from the upload.  If the record is actually new,
                                    // it'll have its own flag already.
                                    if (k === '_isNew') continue;

                                    if (v !== null && v !== undefined) {
                                        // depot_id is a special case (as it can effectively be moving the container).
                                        if (k === 'depot_id') {
                                            // Rules for this are:
                                            // 1. If the depot_id is null, or the row is new, we silently overwrite it with the value from the file.
                                            // 2. If the container has status 'LEFT_DEPOT', we silently overwrite it with the value from the file.
                                            // 3. If the container is currently in the 'blank' depot, we silently overwrite it with the value from the file.
                                            // In any other case, and the depot_id is different, we change it, but add a warning.
                                            if (ocTemp.depot_id == null || ocTemp._isNew || ocTemp.status === 'LEFT_DEPOT' || ocTemp.depot_id === blankDepot?.id) {
                                                ocTemp.depot_id = v;
                                            }
                                            else if (ocTemp.depot_id !== v) {
                                                ocTemp.depot_id = v;
                                                warnings.push(`Container ${ocTemp.reefer_num} moved to ${depots.find(d => d.id === v)?.name}.`);
                                            }
                                            continue;
                                        }

                                        // set_temp and set_humidity are special cases too.
                                        if (k === 'set_temp' || k === 'set_humidity') {
                                            //  If the value is null, or the container hasn't been saved yet, we overwrite it with the value from the file.
                                            if (ocTemp[k] == null || ocTemp._isNew) {
                                                ocTemp[k] = v;
                                            }
                                            // otherwise we ignore the upload value, and add a warning if they differ.
                                            else if (parseFloat(ocTemp[k]) !== parseFloat(v)) {
                                                const fldName = k === 'set_temp' ? 'set point' : 'set humidity';
                                                warnings.push(`Container ${ocTemp.reefer_num} currently has ` +
                                                    `${fldName} ${ocTemp[k]} but upload has ${v}, please correct ` +
                                                    `the ${fldName} in the container or upload file.`);
                                            }
                                            continue;
                                        }

                                        ocTemp[k] = v;
                                    }
                                }
                                return ocTemp;
                            }

                            //  If it's not in the order, but it's in unownedOCs, we claim it.
                            const unowned = unownedOCs.find(o => rn === o.reefer_num);
                            if (unowned) {
                                console.log(`Claiming unowned reefer ${rn}`);
                                claimedReefers.add(rn);

                                //  When we find a matching unowned, we overwrite it with the ocIn,
                                //  (except the uuid, reefer_num, status, depot_id and is_flagged), skipping any null/undefined values in ocIn.
                                let ocTemp = {...unowned}; //  work on a copy.
                                for (const [k, v] of Object.entries(ocIn)) {
                                    if (['reefer_num', 'uuid', 'status', 'is_flagged'].includes(k)) continue;
                                    if (v != null) {

                                        // Same rules as before here for the depot_id.
                                        if (k === 'depot_id') {
                                            if (ocTemp.depot_id == null || ocTemp._isNew || ocTemp.status === 'LEFT_DEPOT' || ocTemp.depot_id === blankDepot?.id) {
                                                ocTemp.depot_id = v;
                                            }
                                            else if (ocTemp.depot_id !== v) {
                                                ocTemp.depot_id = v;
                                                warnings.push(`Container ${ocTemp.reefer_num} moved to ${depots.find(d => d.id === v)?.name}.`);
                                            }
                                            continue;
                                        }

                                        // And likewise for set_temp and set_humidity
                                        if (k === 'set_temp' || k === 'set_humidity') {
                                            //  If the value is null, or the container hasn't been saved yet, we overwrite it with the value from the file.
                                            if (ocTemp[k] == null || ocTemp._isNew) {
                                                ocTemp[k] = v;
                                            }
                                            // otherwise we ignore the upload value, and add a warning if they differ.
                                            else if (parseFloat(ocTemp[k]) !== parseFloat(v)) {
                                                const fldName = k === 'set_temp' ? 'set point' : 'set humidity';
                                                warnings.push(`Container ${ocTemp.reefer_num} currently has ` +
                                                    `${fldName} ${ocTemp[k]} but upload has ${v}, please correct ` +
                                                    `the ${fldName} in the container or upload file.`);
                                            }
                                            continue;
                                        }

                                        ocTemp[k] = v;
                                    }
                                }
                                //  And return our updated entry
                                return ocTemp;
                            }

                            //  No unowned match or oldReefers?  Just mark it as new
                            ocIn._isNew = true;
                            newReefers.add(rn);
                            return ocIn;
                        });

                        // Now go through ocsIn, and ensure that every one of them has a depot_id.
                        // If it doesn't, we'll set it to `getDefaultDepot()`.
                        for (const oc of ocsIn) {
                            if (oc.depot_id == null) {
                                oc.depot_id = getDefaultDepot();
                            }
                        }

                        // Now we need to update ourOcs with ocsIn.
                        // First, iterate through ourOcs overwrite with any matching records in ocsIn
                        ourOcs = ourOcs.map((oc) => {
                            const rn = oc.reefer_num;
                            const newOc = ocsIn.filter(o => rn === o.reefer_num)?.[0];
                            if (newOc) {
                                return newOc;
                            }
                            return oc;
                        });
                        // Then append any new records to ourOcs
                        for (const oc of ocsIn) {
                            if (!oldReefers.has(oc.reefer_num)) {
                                ourOcs.push(oc);
                            }
                        }

                        //  All done, write ourOcs back into the record
                        setRecord((prev) => ({
                            ...prev,
                            ocs: ourOcs
                        }));

                        let msg = `Found ${numLinesInFile} container${numLinesInFile > 1 ? 's' : ''} in the file.`;
                        if (claimedReefers.size > 0) {
                            msg += `<br> &#x2022; ${claimedReefers.size} existing containers were claimed.`;
                        }
                        if (mergedReefers.size > 0) {
                            msg += `<br> &#x2022; ${mergedReefers.size} containers (already in the order) were updated.`;
                        }
                        if (newReefers.size > 0) {
                            msg += `<br> &#x2022; ${newReefers.size} new containers were added.`;
                        }

                        // If there are any warnings, add them as bullet points (up to a limit of 5)
                        if (warnings.length > 0) {
                            msg += '<br><br>The following warnings occurred:';
                            if (warnings.length > 5) {
                                msg += `<br> &#x2022; ${warnings.slice(0, 5).join('<br> &#x2022; ')}<br> &#x2022; and ${warnings.length - 5} more`;
                            }
                            else {
                                msg += `<br> &#x2022; ${warnings.join('<br> &#x2022; ')}`;
                            }
                        }

                        // if there are any missingReefers, we need to give the user the option to delete or remove them.
                        if (missingReefers.size > 0) {

                            msg += `<br><br>The container${missingReefers.size > 1 ? 's' : ''} `;
                            if (missingReefers.size > 5) {
                                msg += `<b>${Array.from(missingReefers).slice(0, 5).join('</b>, <b>')}</b> (and ${missingReefers.size - 5} more) `;
                            }
                            else if (missingReefers.size > 1) {
                                msg += `<b>${Array.from(missingReefers).slice(0, -1).join('</b>, <b>')}</b> and <b>${Array.from(missingReefers).slice(-1)}</b> `;
                            }
                            else {
                                msg += `<b>${Array.from(missingReefers)[0]}</b> `;
                            }
                            msg += `${missingReefers.size > 1 ? 'were' : 'was'} not present in the file.<br>What would you like to do with ${missingReefers.size > 1 ? 'them' : 'it'}?`;

                            const handleMissingKeep = (key) => {
                                closeSnackbar(key);
                                setWaitingSnack(null);
                                // And nothing else: this just means to leave it be.
                            }

                            const handleMissingRemove = (key) => {
                                closeSnackbar(key);
                                setWaitingSnack(null);
                                // Remove the missing reefers from ourOcs
                                ourOcs = ourOcs.filter(oc => !missingReefers.has(oc.reefer_num));
                                // And write it back to the record
                                setRecord((prev) => ({
                                    ...prev,
                                    ocs: ourOcs
                                }));
                            }

                            const handleMissingDelete = (key) => {
                                // This is the same as handleMissingRemove, except we also need to delete the ocs from the database.
                                // We can pass most of this off to handleMissingRemove, but first we need to get the uuids of the ocs
                                // that we're going to delete.
                                const ocsToDelete = ourOcs.filter(oc => missingReefers.has(oc.reefer_num));
                                handleMissingRemove(key);
                                // Convert missingReefers to an array of reefer_nums, and sent it up to the parent.
                                attemptDeleteOcs(ocsToDelete);
                            }

                            const sbId = enqueueSnackbar(msg, {
                                variant: 'info',
                                persist: true,
                                content: (key, message) => (
                                    <div key={key}
                                         style={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                             boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                                             backgroundColor: '#4b4b4b', // Change this color to your desired background color
                                             color: 'white', // Change this color to your desired text color
                                             borderRadius: 4,
                                             padding: 8,
                                         }}>
                                        <div style={{
                                            flexGrow: 1,
                                            marginBottom: 8, // Add margin to separate message from buttons
                                         }} dangerouslySetInnerHTML={{ __html: message }} />
                                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                            <Tooltip title="Keep the order as it's displayed above, with the missing containers in the order.">
                                                <Button onClick={() => {handleMissingKeep(key)}} variant="contained" size="small" sx={{mr: 1}} color="success">
                                                    Keep
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Remove the missing containers from the order, but don't delete them. They can then be added to other orders without loss of their history.">
                                                <Button onClick={() => {handleMissingRemove(key)}} variant="contained" size="small" sx={{mr: 1}} color="secondary">
                                                    Remove
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Remove the missing containers from the order, and attempt to delete them entirely.  Only containers with no actions can be deleted in this way.">
                                                <Button onClick={() => {handleMissingDelete(key)}} variant="contained" size="small" sx={{mr: 1}} color="error">
                                                    Delete
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )
                            });
                            console.log('sbId', sbId);
                            setWaitingSnack(sbId);  //  this disables the 'Save' and 'Delete' buttons until the user responds to the snackbar
                        }
                        else {
                            // Otherwise it's just an informational message.
                            enqueueSnackbar(<span dangerouslySetInnerHTML={{ __html: msg }} />, {
                                variant: warnings.length > 0 ? 'warning' : 'success',
                                autoHideDuration: 15000
                            });
                        }
                    }
                },
                (error) => {
                    enqueueSnackbar(error.response?.data?.message || error.toString(), {variant: 'warning'});
                }
            )
    }

    const onFileDownload = async () => {
        console.log('onFileDownload', record);

        if (record) {
            axios.post('/admin/order/containersFile', {order: record})
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);

                            // We can use the fetch API to convert the base64 string to a blob
                            fetch(`data:${result.data.type};base64,${result.data.data}`).then(r => r.blob())
                                .then(blob => {
                                    saveAs(blob, result.data.filename || 'ShippingListContainers.xlsx');
                                });
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });
        }
    }

    const saveAs = (blob, filename) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);    //  Firefox requires the link to be in the body
        link.click();
        link.parentNode?.removeChild(link);
    }

    // const handleRowEditStart = (params, event) => {
    //     console.log('handleRowEditStart', params, event);
    // };
    //
    // const handleRowEditStop = (params, event) => {
    //     console.log('handleRowEditStop', params, event);
    //
    //     setRowModesModel((oldModel) => {
    //         console.log(oldModel);
    //         return {
    //             ...oldModel,
    //             [params.row.uuid]: { mode: GridRowModes.View }
    //         };
    //     })
    // };

    const processRowUpdate = async (newRow, oldRow) => {
        console.log('processRowUpdate', newRow, oldRow);
        const ocs = [...record.ocs]; // must work on a copy
        const changedIndex = ocs.findIndex(oc => oc.uuid === newRow.uuid);

        //   If this is a new row, and they changed the reefer_num to be one of our unclaimed reefers, claim it.
        if (newRow._isNew ?? false) {
            const rn = newRow.reefer_num;
            if (rn && isReeferValid(rn)) {
                const match = unownedOCs.find(o => o.reefer_num === rn && o.uuid !== newRow.uuid);
                if (match) {
                    console.log(`${match.uuid} is an unownedOC with the same reefer_num, claiming it.`);
                    let tmpRow = {...match};    //  start with the unclaimed oc
                    //  then overwrite it with anything in the row they have entered, that isn't blank/null
                    for (const [k, v] of Object.entries(newRow)) {
                        if (['_isNew', 'uuid'].includes(k)) continue;   //  don't copy these properties
                        if (v == null || v === '') continue;    //  skip empty properties
                        tmpRow[k] = v;  //  copy anything else.
                    }
                    newRow = tmpRow;
                }
            }
        }

        //const oldStatusRow = allStatuses.find(s => s.status === oldRow.status);
        const newStatusRow = allStatuses.find(s => s.status === newRow.status);

        // May need to set in_at and/or out_at, depending on how the status has changed.
        if (!newRow.in_at && newStatusRow?.has_arrived) {
            newRow.in_at = DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT); // .toSQL({includeZone: false, includeOffset: false});
        }
        if (!newRow.out_at && newStatusRow?.has_shipped) {
            newRow.out_at = DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT); // .toSQL({includeZone: false, includeOffset: false});
        }

        // Old way...
        // // If they've just marked it as shipped, but not set a shipped_at, put in the current time
        // if (oldRow.status === CONTAINER_STATUS.PRESENT && newRow.status === CONTAINER_STATUS.GONE && !newRow.out_at) {
        //     newRow.out_at = DateTime.utc().toSQL({includeZone: false, includeOffset: false});
        // }
        // if ([CONTAINER_STATUS.LEFT_DEPOT, CONTAINER_STATUS.DUE].includes(oldRow.status) && newRow.status === CONTAINER_STATUS.PRESENT && !newRow.in_at) {
        //     newRow.in_at = DateTime.utc().toSQL({includeZone: false, includeOffset: false});
        // }

        ocs[changedIndex] = newRow;
        setRecord((prev) => ({
            ...prev,
            ocs
        }))
        return newRow;
    };

    const COLS_KEY = 'adm/Order/EditDialog/cols';
    const [colVis, setColVis] = React.useState(() => {
        const c = localStorage.getItem(COLS_KEY);
        if (c) return JSON.parse(c);
        return {
            out_at: false,
            ship: false,
            sail_date: false,
            // voyage_number: false,  removed per lankel/lankel#34
            customer_note: false,
        }
    })

    const onColVisChange = (newVis) => {
        console.log(newVis);
        setColVis(newVis);
        localStorage.setItem(COLS_KEY, JSON.stringify(newVis));
    }

    const bulkSetShip = React.useCallback((e) => {
        e.stopPropagation();

        // The default is the first `ship` property in `record.ocs` (if there is one)
        let defaultShip = '';
        for (const oc of record.ocs) {
            if (oc.ship) {
                defaultShip = oc.ship.trim().toUpperCase();
                break;
            }
        }

        let newShipName = prompt('Enter the new ship name', defaultShip);
        console.log(newShipName);
        if (!newShipName) return;
        // Trim whitespace
        newShipName = newShipName.trim();
        // And force to uppercase
        newShipName = newShipName.toUpperCase();

        // If it's blank, let them know that they can't do that
        if (!newShipName) {
            enqueueSnackbar('Ship name cannot be blank', {
                variant: 'warning'
            });
        }
        else {
            // Otherwise, set the ship name on all rows
            setRecord((prev) => ({
                ...prev,
                ocs: prev.ocs.map(oc => ({
                    ...oc,
                    ship: newShipName
                }))
            }));
        }
    }, [enqueueSnackbar, record.ocs]);

    const bulkSetSailDate = React.useCallback((e) => {
        e.stopPropagation();

        // The default is the first `sail_date` property in `record.ocs` (if there is one)
        let newSailDate = '';
        for (const oc of record.ocs) {
            if (oc.sail_date && oc.sail_date !== '') {
                const dt = smartParseDate(oc.sail_date, true);
                if (dt.isValid) {
                    // convert it to dd/mm/yyyy
                    newSailDate = dt.toFormat('dd/MM/yyyy');
                    break;
                }
            }
        }

        let haveValidDate = false;
        let newSailDateStr = '';
        let newSailDt = null;
        while (!haveValidDate) {
            newSailDateStr = prompt('Enter the new Vessel Date (in dd/mm/yyyy format)', newSailDate);
            console.log(newSailDateStr);
            if (!newSailDateStr) return;
            // Trim whitespace
            newSailDateStr = newSailDateStr.trim();

            // If it's blank, let them know that they can't do that
            if (!newSailDateStr) {
                // enqueueSnackbar('Sail date cannot be blank', {
                //     variant: 'warning'
                // });
            }
            else {
                // Otherwise, try to parse it
                newSailDt = smartParseDate(newSailDateStr, true);
                if (newSailDt.invalid) {
                    // enqueueSnackbar('Invalid date', {
                    //     variant: 'warning'
                    // });
                }
                else {
                    // And if it's valid, set the flag
                    haveValidDate = true;
                }
            }
        }

        // If we get here, we have a valid date, so set it on all rows
        setRecord((prev) => ({
            ...prev,
            ocs: prev.ocs.map(oc => ({
                ...oc,
                sail_date: newSailDt.toSQLDate()
            }))
        }));
    }, [record.ocs]);

    const columns = React.useMemo(() => [
        {
            field: 'reefer_num',
            headerName: 'Unit',
            description: 'Reefer Number',
            editable: true,
            hideable: false,
            width: 130,
            type: 'string',
            valueFormatter: ({value}) => formatReefer(value),
            valueParser: (value) => value.trim().replace(' ', '').toUpperCase().substring(0, 11),
            // preProcessEditCellProps: (params) => {
            //     console.log(params)
            //     return { ...params.props, error: !isReeferValid(params.props.value)}
            // }
            cellClassName: (params) => {
                return isReeferValid(params.value) ? 'reefer-valid': 'reefer-invalid';
            }
        },
        {
            field: 'depot_id',
            headerName: 'Location',
            description: 'Terminal/Depot Name',
            editable: true,
            flex: 1,
            minWidth: 80,
            sortable: false,
            type: 'singleSelect',
            valueOptions: depots.map(d => ({value: d.id, label: d.name})),
            valueFormatter: ({value}) => depots.filter(d => d.id === value)?.[0]?.name ?? '???'
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'Container Status',
            editable: false,
            minWidth: 80,
            flex: 1,
            maxWidth: 120,
            valueGetter: ({value}) => allStatuses.find(s => s.status === value)?.status_name ?? '- Not Set -',
        },
        {
            field: 'set_temp',
            headerName: 'Set Point',
            description: 'Container Set Point',
            editable: true,
            width: 80,
            sortable: false,
            type: 'number',
            valueFormatter: ({value}) => value == null ? '' : Number(value).toFixed(1) + '°C',
        },
        {
            field: 'set_humidity',
            headerName: 'Set Humidity',
            description: 'Container Set Point',
            editable: true,
            width: 80,
            sortable: false,
            type: 'number',
            valueFormatter: ({value}) => value == null ? '' : Number(value).toFixed(0) + '%',
        },
        {
            field: 'ie',
            headerName: 'I/E Cycle',
            description: 'Import/Export Cycle',
            editable: true,
            width: 80,
            sortable: false,
            type: 'singleSelect',
            valueOptions: ['', 'I', 'E'],
            valueFormatter: ({value}) => value == null ? '' : value,
        },
        {
            field: 'cargo_description',
            headerName: 'Cargo',
            description: 'Customer Cargo Description',
            editable: true,
            flex: 2,
            minWidth: 80,
            sortable: false,
            type: 'string',
        },
        // {
        //     field: 'is_shipped',
        //     headerName: 'Shipped',
        //     description: 'Has the container been shipped?',
        //     editable: true,
        //     width: 40,
        //     sortable: false,
        //     type: 'boolean',
        //     valueGetter: (params) => !!params.row.is_shipped,
        //     valueSetter: (params) => {
        //         return { ...params.row, is_shipped: params.value ? 1 : 0}
        //     }
        // },
        // due_date is gone from the oc now, it's part of the order instead...
        // {
        //     field: 'due_date',
        //     headerName: 'Due Date',
        //     description: 'Scheduled arrival date.',
        //     editable: true,
        //     flex: 1,
        //     minWidth: 100,
        //     sortable: false,
        //     type: 'date',
        //     valueGetter: (params) => {
        //         if (!params.value) return null;
        //         //  Stored as a UTC mysql string, but we need a date.
        //         return DateTime.fromSQL(params.value).toJSDate();
        //     },
        //     valueSetter: (params) => {
        //         let newVal = null;
        //         if (params.value) {
        //             newVal = DateTime.fromJSDate(params.value).toUTC()
        //                 .toSQLDate();
        //         }
        //         return { ...params.row, due_date: newVal };
        //     }
        // },
        // {
        //     field: 'out_at',
        //     headerName: 'Dispatched On',
        //     description: 'When the container was dispatched.',
        //     editable: true,
        //     flex: 1,
        //     minWidth: 100,
        //     sortable: false,
        //     type: 'dateTime',
        //     valueGetter: (params) => {
        //         if (!params.value) return null;
        //         //  Stored as a UTC mysql string, but we need a date.
        //         return DateTime.fromSQL(params.value, { zone: 'utc' }).toLocal().toJSDate();
        //     },
        //     valueSetter: (params) => {
        //         let newVal = null;
        //         if (params.value) {
        //             newVal = DateTime.fromJSDate(params.value).toUTC()
        //                 .toSQL({includeZone: false, includeOffset: false});
        //         }
        //         return { ...params.row, out_at: newVal };
        //     }
        // },
        {
            field: 'ship',
            headerName: 'Ship',
            description: 'Name of Vessel',
            editable: true,
            flex: 1,
            minWidth: 80,
            sortable: false,
            type: 'string',
            renderHeader: () => (
                <span>
                    <Tooltip title="Name of Vessel">
                        <strong>Ship</strong>
                    </Tooltip>
                    <IconButton size="small"
                                title={'Set ship for all containers'}
                                onClick={bulkSetShip}
                    >
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </span>
            ),
        },
        {
            field: 'sail_date',
            headerName: 'Vessel Date',
            description: 'Scheduled sail date.',
            editable: true,
            flex: 1,
            minWidth: 100,
            sortable: false,
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) return null;
                //  Stored as a UTC mysql string, but we need a date.
                return DateTime.fromSQL(params.value).toJSDate();
            },
            valueSetter: (params) => {
                let newVal = null;
                if (params.value) {
                    newVal = DateTime.fromJSDate(params.value)//.toUTC()
                        .toSQLDate();
                }
                return { ...params.row, sail_date: newVal };
            },
            renderHeader: () => (
                <span>
                    <Tooltip title="Scheduled sail date">
                        <strong>Vessel Date</strong>
                    </Tooltip>
                    <IconButton size="small"
                                title={'Set vessel date for all containers'}
                                onClick={bulkSetSailDate}
                    >
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </span>
            ),
        },
        {
            field: 'booking_ref',
            headerName: 'Booking Ref',
            description: 'Container Booking Reference',
            editable: true,
            flex: 1,
            minWidth: 50,
            sortable: false,
            type: 'string',
        },
        // Removed per lankel/lankel#34
        // {
        //     field: 'voyage_number',
        //     headerName: 'Voyage Number',
        //     description: 'Customer Voyage Number',
        //     editable: true,
        //     flex: 1,
        //     minWidth: 50,
        //     sortable: false,
        //     type: 'string',
        // },
        {
            field: 'customer_note',
            headerName: 'Notes',
            description: 'Customer Notes',
            editable: true,
            flex: 1,
            minWidth: 80,
            sortable: false,
            type: 'string',
        },
        {
            field: 'actions',
            type: 'actions',
            hideable: false,
            width: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    title="Remove this row"
                    label="Delete"
                    onClick={() => onDeleteContainer(params.id)}
                />
            ]
        }
    ], [depots, allStatuses, bulkSetShip, bulkSetSailDate, onDeleteContainer]);

    console.log(record);
    const isValid = (() => {
        if (!record.customer_id) return false;
        if (!record.port_code) return false;

        const validDepotIds = depots.map(d => d.id); // just gets the ids
        let reeferNums = [];

        for (const oc of record.ocs) {
            if (!isReeferValid(oc.reefer_num)) return false;
            //  The depot they've selected (if any) must be in the correct port
            if (oc.depot_id && !validDepotIds.includes(oc.depot_id)) return false;
            if (reeferNums.includes(oc.reefer_num)) return false;   //  can't duplicate these
            reeferNums.push(oc.reefer_num);
        }
        return true;
    })();

    return (
        <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth='xl'>
            <DialogTitle>{isNew ? 'New': 'Edit'} Shipping List</DialogTitle>
            <DialogContent>
                {   error && (
                    <Alert severity="error">{error.message || error}</Alert> )
                }
                <Stack direction="row" spacing={0} useFlexGap flexWrap={"wrap"}>
                    <FormControl variant="standard" sx={{ minWidth: 150, mr: 1 }}>
                        <InputLabel id="cust-name-label">Customer</InputLabel>
                        <Select
                            labelId="cust-name-label"
                            label="Customer"
                            id="cust-name"
                            name="customer_id"
                            value={record.customer_id || ''}
                            onChange={onFormChange}
                        >
                            {
                                customers.map((c) => <MenuItem value={c.id} key={c.id}>{c.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{minWidth: "150px", mr: 1}}>
                        <InputLabel id="order-port-id-label">Port</InputLabel>
                        <Select
                            labelId="order-port-id-label"
                            id="order-port-id"
                            name="port_code"
                            value={record.port_code}
                            label="Port"
                            onChange={onPortChange}
                        >
                            {
                                Object.entries(props.ports).filter(([k,v]) => k !== '')
                                    .map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{minWidth: "150px", mr: 1}}>
                        <InputLabel id="order-teriminal-label">Terminal</InputLabel>
                        <Select
                            labelId="order-teriminal-label"
                            id="order-teriminal"
                            name="terminal_id"
                            value={record.terminal_id || ''}
                            label="Terminal"
                            onChange={onFormChange}
                        >
                            {
                                depots.map((d) => <MenuItem value={d.id} key={d.id}>{d.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{minWidth: "150px", mr: 1}}>
                        <InputLabel id="imp-exp-label">Import/Export</InputLabel>
                        <Select
                            labelId="imp-exp-label"
                            id="import_export"
                            name="import_export"
                            value={record.import_export || 'IMP'}
                            label="Import/Export"
                            onChange={onFormChange}
                        >
                            {
                                Object.entries(importExport).filter(([k,v]) => k !== '')
                                    .map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        // margin="dense"
                        sx={{minWidth: "150px", mr: 1}}
                        id="booking_ref"
                        name="booking_ref"
                        label="Ship Ref"  // DB 2023-01-16: Renamed to avoid confusion with new booking_ref field in oc.
                        type="text"
                        variant="standard"
                        value={record.booking_ref || ''}
                        onChange={onFormChange}
                    />
                    <TextField
                        // margin="dense"
                        label="Date"
                        id="due_date"
                        name="due_date"
                        type="date"
                        variant="standard"
                        value={record.due_date || ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={onFormChange}
                    />
                    <FormControlLabel control={<Switch checked={record.completed}
                                                       onChange={onCompletedChange}
                                                       sx={{ml: 2}}
                                                       color={record.completed ? 'success' : 'default'} />}
                                      label="Completed" />
                </Stack>

                <Box sx={{
                    height: 400, width: '100%',
                    '& .reefer-invalid': {
                        bgcolor: 'rgb(253, 237, 237)'
                    }
                }}>
                    <DataGrid
                        density="compact"
                        sx={{
                            border: 0,
                            //  Remove the 16px left & right padding on cells during edit
                            cell: { padding: 0 },
                            'cell--editing': { padding: 0 },
                            cellContent: { padding: 0 },    // @todo: fix these
                            'cell--textLeft': { padding: 0 },
                        }}
                        loading={record.ocs == null}
                        columns={columns}
                        rows={record.ocs ?? []}
                        getRowId={(r) => r.uuid}
                        columnVisibilityModel={colVis}
                        onColumnVisibilityModelChange={onColVisChange}

                        // rowModesModel={rowModesModel}
                        // onRowEditStart={handleRowEditStart}
                        // onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        components={{
                            Toolbar: OCToolbar,
                            NoRowsOverlay: () => <Box sx={{ textAlign: 'center', p: 3}}
                                                      color="text.disabled">No Containers in this Shipping List</Box>
                        }}
                        componentsProps={{
                            toolbar: {
                                unownedOCs,
                                depots,
                                order: record,
                                onAddContainer,
                                // setRowModesModel,
                                onFileUpload,
                                onFileDownload,
                            }
                        }}
                        hideFooter={record.ocs == null ? true : record.ocs.length < 100}
                        pageSize={100}
                        //disableColumnFilter={true}
                        disableDensitySelector={true}
                        disableColumnMenu={true}
                        editMode="row"
                        experimentalFeatures={{newEditingApi: true}}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                {!isNew && <Button onClick={handleDelete} color="error" style={{marginRight: 'auto'}}
                                   disabled={saving || !!waitingSnack}>Delete</Button>}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid || saving || !!waitingSnack}>{isNew ? 'New Shipping List' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    record: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    ports: PropTypes.object.isRequired,
}
import React from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import {
    Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
    FormControlLabel, IconButton, InputAdornment, Link, MenuItem, Radio, RadioGroup, Switch, TextField, ToggleButton,
    ToggleButtonGroup, useTheme
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    ACT_TYPE, ACT_TYPE_WITH_DATA, formatReefer, isReeferValid, LUXON_MYSQL_DATETIME_FMT, VENT_POSNS
} from "../../../common/shared";
import PropTypes from "prop-types";
import {DateTime} from "luxon";
import {AuthContext} from "../../../contexts/AuthProvider";
import ActionIcon from "../../../components/ActionIcon";
import {slotToDateTime, slotToTime, timeToSlot} from "./defaults";


// Lane names are strings, but we want them sorted as if they were numbers (if possible), hence this:
const sortLanes = (lanes) => lanes.sort((a, b) => a.name.localeCompare(b.name, 'en', {numeric: true}));


export default function AddContainerDialog(props) {
    const [record, setRecord] = React.useState(props.record || {
        // OrderContainer fields
        reefer_num: '',
        order_id: null,
        depot_id: props.depotId,
        set_temp: null,
        set_humidity: null,
        status: 'DUE',
        in_at: null,
        out_at: null,
        due_date: null,
        cargo_description: null,
        customer_note: null,
        release_no: null,
        ship: null,
        sail_date: null,
        voyage_number: null,
        lane_id: null,
        invoice_id: null,
        invoice_status: 'NO',
        booking_ref: null,
        vent: 'CLOSED',
        ie: null,
        is_flagged: 0,
    });
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [arrivalDate, setArrivalDate] = React.useState(DateTime.local().toSQLDate());
    const [arrivalSlot, setArrivalSlot] = React.useState(timeToSlot(DateTime.local().toFormat('HH:mm')));
    const [laneGroupId, setLaneGroupId] = React.useState(props.laneGroupId);
    // customerId gets its own state, as it's not part of the record (actually part of the Container record).
    const [customerId, setCustomerId] = React.useState(null);
    const depot = useLiveQuery(async () => {
        // Need a custom fn here when it's loaded, as it affects the default value for 'ie'.
        const d = await db.Depot.get(props.depotId);
        // console.log(d, record.ie);
        if (d && record.ie == null) {
            // console.log('setting ie', d.entry_type === 'DEPOT' ? 'E' : 'I');
            setRecord({...record, ie: d.entry_type === 'DEPOT' ? 'E' : 'I'});
        }
        return d;
    }, [props.depotId]);
    const port = useLiveQuery(() => depot ? db.Port.get(depot?.port_code) : undefined, [depot]);
    const lanesAvailable = useLiveQuery(() => {
        const coll = laneGroupId ? db.Lane.where('lane_group_id').equals(laneGroupId) :
            db.Lane.where('depot_id').equals(props.depotId ?? 0);

        return coll.toArray(arr => {
            // console.log(arr);
            // If there's only one lane, set it (there's no other option available)
            if (arr && arr.length === 1 && record && !record.lane_id) {
                setRecord({...record, lane_id: arr[0].id});
            }

            return sortLanes(arr);
        });

    }, [props.depotId, laneGroupId], []);
    const customers = useLiveQuery(() => db.Customer.orderBy('name').toArray(), [], []);
    //const allStatuses = useLiveQuery(() => db.ContainerStatus.toArray(), [], []);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const auth = React.useContext(AuthContext);

    // Get a list of unshipped containers in the same port with the same reefer_num.
    const dupEntries = useLiveQuery(async () => {
        if (!record.reefer_num || !depot) return [];
        if (!isReeferValid(record.reefer_num)) return [];
        const depotsInPort = await db.Depot.where({port_code: depot.port_code}).toArray();
        const dupOcs = await db.OrderContainer.where({
            reefer_num: record.reefer_num,
        }).and(oc => oc.status !== 'GONE').toArray();
        const depotsById = depotsInPort.reduce((o, c) => ({...o, [c.id]: c}), {});
        // First get the ones in this port (with a depot_id)
        let arr = dupOcs.filter(oc => Object.keys(depotsById).includes(oc.depot_id?.toString()));
        // Then add any without a depot_id, but belonging to an order for this port.
        await Promise.all(dupOcs.filter(oc => !oc.depot_id && oc.order_id).map(oc => {
            return db.Order.get(oc.order_id).then(order => {
                if (order.port_code === depot.port_code) {
                    arr.push(oc);
                }
            });
        }));
        // finally add a depot record to each of them (will be undefined for those without a depot)
        return arr.map(oc => ({...oc, depot: depotsById[oc.depot_id]}));

    }, [record.reefer_num, depot]);

    /**
     * These are the auto-actions for this depot, port, or location_type
     * @type {ActionType[]}  An array of ActionTypes, in the correct order, with added fields 'required' and 'default_on'
     */
    //const [autoActionTypes, setAutoActionTypes] = React.useState([]);

    /**
     * And these are the actual Actions (along with an extra '_enabled' field, which says if they're turned on or not)
     */
    const [autoActions, setAutoActions] = React.useState([]);

    // Both the above are populated when the depot is loaded as follows:
    React.useEffect( () => {
        const getAutoActions = async () => {
            if (!depot) {
                //setAutoActionTypes([]);
                return;  // depot not loaded yet.
            }
            // First check if there are auto-actions specific to this depot
            let autoActs = await db.AutoAction.where('depot_id').equals(depot.id).first();

            if (!autoActs) {
                // Failing that, try for this port
                autoActs = await db.AutoAction.where('port_code').equals(depot.port_code).first();
            }

            if (!autoActs) {
                // Failing that, try for this location_type
                autoActs = await db.AutoAction.where('location_type').equals(depot.entry_type).first();
            }

            if (!autoActs) {
                //setAutoActionTypes([]);
                return;   //  no auto actions
            }

            //  Now we have the auto action, we need to get the action types
            const aas = await db.AutoActionActionType.where('auto_action_id').equals(autoActs.id).sortBy('sort_order')
                .then( aats => {
                    const aatPromises = aats.map(aat => db.ActionType.get(aat.action_type_id).then(at => {
                        return {...at, required: aat.required, default_on: aat.default_on};
                        }));
                    return Promise.all(aatPromises);
                });
            //setAutoActionTypes(aas);

            // Now populate the actions (if not already populated)
            const newAutoActions = [];
            aas.forEach(aat => {
                const newAction = {
                    action_type_id: aat.id,
                    depot_id: depot.id,
                    recorded_at: null,  // populate these two later
                    recorded_by: null,  // when we're saving.
                    data_value: null,
                    action_date: DateTime.local('Europe/Dublin').toSQLDate(),
                    action_time: DateTime.local('Europe/Dublin').set({second: 0, millisecond: 0}).toISOTime(
                        {suppressSeconds: true, suppressMilliseconds: true, includeOffset: false}),
                    time_slot: null,
                    deleted: 0,
                    _actionType: aat,
                    _enabled: (aat.default_on || aat.required),
                    _required: (aat.required),
                };
                //  If the action has a slot, set a sensible default value for it
                if (aat.has_slot) {
                    const irlTime = DateTime.local({ zone: "Europe/Dublin" }).set({second: 0, millisecond: 0}).toFormat('HH:mm');
                    if (irlTime < '11:45') newAction.time_slot = 'AM';
                    else if (irlTime <= '15:00') newAction.time_slot = 'MD';
                    else newAction.time_slot = 'PM';
                }
                newAutoActions.push(newAction);
            });
            setAutoActions(newAutoActions);
        };
        getAutoActions();
    }, [depot]);

    // console.log('autoActions', autoActions);


    const handleClose = () => {
        props.onCancel();
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (!isReeferValid(record.reefer_num)) return;

        //  This has to work offline, so we only talk to dexie here.

        //  All the changes go into a tx
        db.transaction('rw', db.Container, db.OrderContainer, db.Action,async () => {
            //  Start by ensuring we have the container record
            const cont = await db.Container.get(record.reefer_num);
            if (!cont) await db.Container.add({reefer_num: record.reefer_num, remarks: '', customer_id: customerId});
            else {
                // May need to update the customer_id
                if (customerId && cont.customer_id !== customerId) {
                    await db.Container.update(record.reefer_num, {customer_id: customerId});
                }
            }

            //  Check if this container already exists in the depot (and is not shipped)
            const oc = await db.OrderContainer.where({
                reefer_num: record.reefer_num,
                depot_id: props.depotId,
            }).filter(o => o.in_at && !o.out_at);
            if (oc.length) throw new Error(`Container ${formatReefer(record.reefer_num)} is already in this location.`);

            // Make sure that the record has created_at and created_by
            if (!record.created_at) record.created_at = DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT); //.toSQL({includeZone: false, includeOffset: false});
            if (!record.created_by) record.created_by = auth.user.id;

            // Set the correct status
            let intialStatus = 'DUE';
            // If we're adding it to a depot, the initial status should be 'EMPTY_IN'
            if (depot?.entry_type === 'DEPOT') intialStatus = 'EMPTY_IN';

            // If one of our autoActions is ARRIVED, then we need to mark it as such
            const arrivedAction = autoActions.find(aa => aa._actionType?.data_type === ACT_TYPE.ARRIVED && aa._enabled);
            if (arrivedAction) {
                if (arrivedAction._actionType?.set_status_to) intialStatus = arrivedAction._actionType.set_status_to;
                record.in_at = slotToDateTime(arrivalDate, arrivalSlot);
            }
            record.status = intialStatus;

            //  Add a new order container record
            const uuid = await db.OrderContainer.add(record);
            //  Update the record with the uuid
            setRecord((prev) => ({
                ...prev,
                uuid: uuid
            }));

            // Now add the auto actions
            autoActions.forEach(aa => {
                // console.log(aa);

                if (aa._enabled) {
                    // Copy every property from aa to a new object, except the _ properties
                    const newAction = Object.fromEntries(Object.entries(aa).filter(([key, value]) => !key.startsWith('_')));
                    newAction.order_container_uuid = uuid;
                    newAction.depot_id = props.depotId;
                    newAction.recorded_at = DateTime.utc().toFormat(LUXON_MYSQL_DATETIME_FMT);
                    newAction.recorded_by = auth.user.id;
                    newAction.action_date = arrivalDate;
                    newAction.action_time = slotToTime(arrivalDate, arrivalSlot);
                    newAction.time_slot = arrivalSlot;

                    // console.log('newAction', newAction);

                    db.Action.add(newAction);
                }
            });
        }).then(() => {
            props.afterSave(record);
        }).catch(err => {
            setError(err);
        }).finally(() => {
            setSaving(false);
        })
    };

    const onReeferChange = (event) => {
        //  This gets its own handler to force it to uppercase
        const newNum = event.target.value.toUpperCase().replaceAll(' ', '').substring(0, 11);

        setRecord((prev) => ({
            ...prev,
            reefer_num: newNum
        }));

        // If the reefer is valid, check if we have a customer for it
        if (isReeferValid(newNum)) {
            db.Container.where('reefer_num').equals(newNum).first().then(cont => {
                if (cont && cont.customer_id) {
                    setCustomerId(cont.customer_id);
                }
            });
        }
    };

    const onBookingRefChange = (event) => {
        // this also gets its own handler to force it to uppercase, and to not allow spaces (except as the last character)
        let newRef = event.target.value.toUpperCase();
        // if the last character isn't a space, remove any spaces.
        if (newRef.length > 0 && newRef[newRef.length - 1] !== ' ') {
            newRef = newRef.replaceAll(' ', '');
        }

        setRecord((prev) => ({
            ...prev,
            booking_ref: newRef
        }));
    };

    const onSetTempChange = (event) => {
        // this gets its own handler to ensure that we store it as a number
        setRecord((prev) => ({
            ...prev,
            set_temp: event.target.value === '' ? null: +event.target.value
        }));
    };

    const onSetHumidityChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            set_humidity: event.target.value === '' ? null: +event.target.value
        }));
    }

    // This handles changes to fields not above (currently just the cargo description).
    const onFieldChange = (event) => {
        setRecord((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const onDataValueChange = (actionIndex, data_type, newValue) => {
        // console.log(actionIndex, data_type, newValue);
        setAutoActions((prev) => {
            const newActions = [...prev];
            newActions[actionIndex].data_value = newValue;
            return newActions;
        });
    }

    const onArrivalDateChange = (event) => {
        setArrivalDate(event.target.value);

        // Also need to update all the actions with the new date.
        setAutoActions((prev) => {
            const newActions = [...prev];
            newActions.forEach(a => {
                a.action_date = event.target.value;
            });
            return newActions;
        });
    }

    const onArrivalSlotChange = (event) => {
        setArrivalSlot(event.target.value);
        const asTime = slotToTime(arrivalDate, event.target.value);

        // Also need to update all the actions with the new slot (and time).
        setAutoActions((prev) => {
            const newActions = [...prev];
            newActions.forEach(a => {
                a.time_slot = event.target.value;
                a.action_time = asTime;
            });
            return newActions;
        });
    }

    const actionsAreComplete = React.useCallback(() => {
        // `every` returns true if all elements in the array pass the test, false otherwise; and it stops at the first
        // false.
        return autoActions.every(a => {
            if (a._enabled) {

                // These are copied from the ActionDialog.js, and should match them (except for actions that are
                // impossible here, set as the various SET* ones).
                if ([ACT_TYPE.SETTEMP, ACT_TYPE.RECTEMP, ACT_TYPE.SETVENT].includes(a._actionType.data_type) ||
                    // And these are everything else that can have data.
                    (ACT_TYPE_WITH_DATA.includes(a._actionType.data_type) && a._actionType.data_required)) {
                    // console.log(a._actionType.data_type, a.data_value);
                    if (a.data_value == null) return false;
                    if (`${a.data_value}`.trim() === '') return false;
                }

                if (a._actionType.has_slot && !a.time_slot) return false;
            }
            return true;
        });
    }, [autoActions]);

    const isValidReeferNum = isReeferValid(record.reefer_num);
    const showReeferInvalid = !!record.reefer_num && !isValidReeferNum;

    const isValid = isValidReeferNum &&
        (record.set_temp !== null) &&
        customerId &&
        ((record.set_temp >= -50 && record.set_temp <= 130) || record.set_temp > 999) &&
        (lanesAvailable?.length === 0 || record.lane_id) &&
        (actionsAreComplete());
    // console.log(dupEntries);

    // console.log(record);
    // console.log(lanesAvailable);

    return (
        <form>
            <Dialog open={true} onClose={handleClose} maxWidth={'xl'} fullScreen={fullScreen}>
                <DialogTitle>Add Container</DialogTitle>

                <DialogContent dividers={true}>
                    {(() => {
                        if (error) {
                            return <Alert severity="error">{error.message || error}</Alert>;
                        }
                        else if (dupEntries && dupEntries.length) {
                            return <Alert severity="warning">{
                                dupEntries.map(oc => {
                                    return (
                                        <div key={oc.uuid}>
                                            <span>{`${formatReefer(oc.reefer_num)} is already in ${oc.depot?.name ?? 'the port'}. `}</span>
                                            {props.openOC && <Link component="a" href={`?oc=${oc.uuid}`} variant="inherit"
                                                                   onClick={(e) => {
                                                                       e.preventDefault();
                                                                       props.openOC(oc);
                                                                       props.onCancel();
                                                                   }}>View</Link>}
                                        </div>
                                    );
                                })
                            }</Alert>;
                        }
                    })()}
                    <DialogContentText>
                        Add an unlisted container to <strong>{depot?.name}</strong> in <strong>{port?.name}</strong>.<br />Enter the details below:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="reefer_num"
                        name="reefer_num"
                        label="Container Number"
                        helperText="Reefer Number"
                        type="text"
                        variant="standard" size="small"
                        required={true}
                        error={showReeferInvalid}
                        value={record.reefer_num ? formatReefer(record.reefer_num): ''}
                        onChange={onReeferChange}
                        sx={{width: '11em', mr: 1}}
                        inputProps={{spellCheck: 'false', size: 13, maxLength: 14}}
                    />
                    <TextField
                        margin="dense"
                        id="set_temp"
                        name="set_temp"
                        label="Set Temp"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                        }}
                        type="number"
                        variant="standard" size="small"
                        inputProps={{ step: 0.1, maxLength: 5, size: 5 }}
                        required={true}
                        value={record.set_temp == null ? '' : record.set_temp}
                        sx={{mr: 1}}
                        onChange={onSetTempChange}
                        error={record.set_temp !== null && (record.set_temp < -50 || record.set_temp > 130) && record.set_temp < 999}
                        helperText={record.set_temp !== null && (record.set_temp < -50 || record.set_temp > 130) && record.set_temp < 999 ?
                            '-50...130, or >999' : 'Container Setting'}
                    />
                    <TextField
                        margin="dense"
                        id="set_humidity"
                        name="set_humidity"
                        label="Humidity"
                        helperText="Container Setting"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        type="number"
                        variant="standard" size="small"
                        inputProps={{ min: 10, max: 100, step: 1, maxLength: 3, size: 3 }}
                        required={false}
                        value={record.set_humidity || ''}
                        sx={{mr: 1}}
                        onChange={onSetHumidityChange}
                    />
                    <TextField
                        margin="dense"
                        name="ie"
                        id="ie"
                        label="Import/Export"
                        helperText="Cycle"
                        select
                        variant="standard" size="small"
                        value={record.ie || 'I'}
                        sx={{mr: 1}}
                        onChange={onFieldChange}
                    >
                        <MenuItem key='I' value={'I'}>I</MenuItem>
                        <MenuItem key='E' value={'E'}>E</MenuItem>
                    </TextField>
                    <TextField
                        margin="dense"
                        name="vent"
                        id="vent"
                        label="Vent"
                        helperText="Vent Posn"
                        select
                        variant="standard" size="small"
                        value={record.vent || 'CLOSED'}
                        sx={{mr: 1}}
                        onChange={onFieldChange}
                    >
                        {
                            Object.entries(VENT_POSNS).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
                        }
                    </TextField>
                    {lanesAvailable?.length > 0 &&
                        <TextField
                            margin="dense"
                            id="lane_id"
                            name="lane_id"
                            label="Lane"
                            helperText="Lane Name"
                            select
                            variant="standard" size="small"
                            value={record.lane_id || ''}
                            required={true}
                            onChange={onFieldChange}
                            sx={{mr: 1}}
                        >
                            {/*<MenuItem key="no-lane" value={''}>- None -</MenuItem>*/}
                            {laneGroupId && <MenuItem key="all-lanes" onClick={() => setLaneGroupId(null)}>- Show All Lanes -</MenuItem>}
                            {lanesAvailable.map(lane => (<MenuItem key={lane.id} value={lane.id}>{lane.name}</MenuItem>))}
                        </TextField>
                    }
                    <TextField
                        margin="dense"
                        id="booking_ref"
                        name="booking_ref"
                        label="Booking Ref"
                        helperText="Booking Reference"
                        type="text"
                        variant="standard" size="small"
                        value={record.booking_ref || ''}
                        onChange={onBookingRefChange}
                        sx={{mr: 1}}
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        id="customer_id"
                        name="customer_id"
                        label="Customer"
                        helperText="Select Customer"
                        select
                        variant="standard" size="small"
                        value={customerId || ''}
                        required={true}
                        onChange={(event) => setCustomerId(event.target.value)}
                        sx={{mr: 1}}
                    >
                        <MenuItem key="no-customer" value={null}>- None -</MenuItem>
                        {customers.map(customer => (<MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>))}
                    </TextField>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="cargo_description"
                        name="cargo_description"
                        label="Cargo Description"
                        helperText="Container Contents (Optional)"
                        type="text"
                        variant="standard" size="small"
                        value={record.cargo_description ?? ''}
                        onChange={onFieldChange}
                        sx={{mr: 1}}
                    />
                    {
                        // if we have any auto actions, we need a date and time for them.
                        autoActions.length > 0 &&
                        <Box display={'flex'} sx={{mb: 1}}>
                            <TextField
                                margin="dense"
                                variant="standard" size="small"
                                id="action_date"
                                name="action_date"
                                label="Date"
                                type="date"
                                value={arrivalDate}
                                required={true}
                                sx={{ minWidth: 140 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: DateTime.local().toSQLDate(), // can't be later than today
                                    // Could also set `min` to props.oc.arrived_at, but better not in case
                                    // they're late marking it as arrived.
                                }}
                                onChange={onArrivalDateChange}
                            />
                            <ToggleButtonGroup
                                size="small"
                                aria-label="Time Slot"
                                sx={{ml: 1, alignSelf: 'flex-end'}}
                                value={arrivalSlot}
                                exclusive
                                onChange={onArrivalSlotChange}
                            >
                                <ToggleButton value='AM' key='AM'>AM</ToggleButton>
                                <ToggleButton value='MD' key='MD'>MD</ToggleButton>
                                <ToggleButton value='PM' key='PM'>PM</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    }
                    {
                        // and for each autoAction, add a box with a switch, and anything else it needs.
                        autoActions.map((action, i) => {
                            const actionType = action._actionType;
                            return (
                                <Box display="flex" key={i}>
                                    <FormControlLabel title={actionType.action_message || 'Add this action'}
                                                      control={<Switch name="_enabled" checked={!!action._enabled}
                                                                       disabled={!!action._required}
                                                                       onChange={(e) => {
                                                                            action._enabled = e.target.checked ? 1 : 0;
                                                                            setAutoActions([...autoActions]);
                                                                       }} />}
                                                      label={<Box display="flex">
                                                          <ActionIcon
                                                              iconData={actionType.icon_data}
                                                              sx={{ color: actionType.icon_color || '#000000' }}
                                                          />
                                                          {actionType.label}
                                                          {!!action._enabled && ACT_TYPE_WITH_DATA.includes(actionType.data_type) ? ': ' : ''}
                                                      </Box>}
                                    />

                                    {(!!action._enabled) && (() => {
                                        switch (actionType.data_type) {
                                            default:
                                            case ACT_TYPE.NONE:
                                                return <></>;
                                            case ACT_TYPE.BOOL:
                                                return (
                                                    <FormControl>
                                                        <RadioGroup row name="data_value"
                                                                    value={action.data_value || '0'}
                                                                    onChange={e => onDataValueChange(i, actionType.data_type, e.target.value)}
                                                        >
                                                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                            <FormControlLabel value="0" control={<Radio />} label="No" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                );
                                            case ACT_TYPE.FLOAT:
                                            case ACT_TYPE.INT:
                                            case ACT_TYPE.RECTEMP:
                                                return (
                                                    <TextField
                                                        // margin="dense"
                                                        name="data_value"
                                                        // label={actionType.label}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">
                                                                <IconButton onClick={() => {
                                                                    onDataValueChange(i, actionType.data_type, -1*action.data_value);
                                                                }}>±</IconButton>
                                                            </InputAdornment>,
                                                            endAdornment: actionType.data_unit ?
                                                                <InputAdornment position="end">{
                                                                    actionType.data_unit
                                                                }</InputAdornment> :
                                                                undefined
                                                        }}
                                                        type="number"
                                                        variant="standard" size="small"
                                                        sx={{ width: 150, mt: 0.8 }}
                                                        inputProps={{ step: [ACT_TYPE.FLOAT, ACT_TYPE.RECTEMP].includes(actionType.data_type) ? 0.1: 1 }}
                                                        required={!!actionType.data_required || [ACT_TYPE.SETHUMID, ACT_TYPE.RECTEMP].includes(actionType.data_type)}
                                                        value={(action.data_value == null) ? '' : action.data_value}
                                                        onChange={(e) => onDataValueChange(i, actionType.data_type, e.target.value)}
                                                    />
                                                );
                                            case ACT_TYPE.TEXT:
                                            case ACT_TYPE.FLAG:
                                            case ACT_TYPE.UNFLAG:
                                                return (
                                                    <TextField
                                                        multiline
                                                        minRows={1}
                                                        // margin="dense"
                                                        name="data_value"
                                                        // label={actionType.label}
                                                        placeholder={actionType.action_message || "Enter some text..."}
                                                        maxRows={8}
                                                        inputProps={{ maxLength: 1000 }}
                                                        variant="standard" size="small"
                                                        sx={{ flexGrow: 1, mt: 0.8 }}
                                                        required={!!actionType.data_required}
                                                        value={(action.data_value === undefined || action.data_value === null) ? '' : action.data_value}
                                                        onChange={(e) => onDataValueChange(i, actionType.data_type, e.target.value)}
                                                    />
                                                );
                                        }
                                    })()}
                                </Box>
                            );
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {(() => {
                        if (dupEntries && dupEntries.length > 0) {
                            // Can't save if they have duplicate entries: see lankel/lankel#56
                            // Change the button to a 'View' button, which just opens the first duplicate
                            const firstDup = dupEntries[0];
                            return <Button onClick={(e) => {
                                        e.preventDefault();
                                        props.openOC(firstDup);
                                        props.onCancel();
                                    }
                                    }>View</Button>;
                        }
                        else {
                            return <Button type="submit" onClick={handleSave} disabled={!isValid || saving}>Save</Button>;
                        }
                    })()}
                </DialogActions>
            </Dialog>
        </form>
    );
}
AddContainerDialog.propTypes = {
    depotId: PropTypes.number.isRequired,
    laneGroupId: PropTypes.number,
    afterSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    openOC: PropTypes.func
}
import React from "react";
import PropTypes from "prop-types";
import {
    Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, InputAdornment,
    MenuItem, Switch, TextField, ToggleButton, ToggleButtonGroup, useTheme
} from "@mui/material";
import {
    formatReefer, isReeferValid, LUXON_MYSQL_DATETIME_FMT, MAGIC_TIME, PORTS, VENT_POSNS
} from "../../../common/shared";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";
import {DateTime} from "luxon";
import {useSnackbar} from "notistack";

const swapKeysAndVals = (obj) => Object.fromEntries(Object.entries(obj).map(a => a.reverse()));

// MAGIC_TIME flipped so that its keys are 'AM', 'MD' and 'PM'.
const MAGIC_TIME_INV = swapKeysAndVals(MAGIC_TIME);

/**
 * Take a full iso datetime 'YYYY-MM-DD HH:ii:ss' in UTC, and return the appropriate timeslot ('AM', 'MD', or 'PM').
 * @param dt_str
 * @returns {string|null}
 */
const dateTimeToSlot = (dt_str, inTz = 'utc') => {
    if (!dt_str) return null;
    // dt_str will be in UTC, but we want it in irish time
    const time_str_irish = DateTime.fromSQL(dt_str, {zone: inTz}).setZone('Europe/Dublin')
        .toISOTime({suppressSeconds: false, suppressMilliseconds: true, includeOffset: false});
    const slot = MAGIC_TIME[time_str_irish];
    if (slot) return slot;
    // If the time isn't exactly a slot, we coerce it to the nearest one.
    if (time_str_irish < '11:45:00') return 'AM';
    if (time_str_irish <= '15:00:00') return 'MD';
    return 'PM';
}

/**
 * Take an iso date (YYYY-MM-DD) and a slot ('AM', 'MD', or 'PM') and return a utc datetime in sql format.
 * @param dt_str
 * @param slot
 * @returns {string|null}
 */
const slotToDateTime = (dt_str, slot) => {
    if (!slot || !dt_str) return null;
    const dt_str_irish = dt_str + ' ' + MAGIC_TIME_INV[slot];
    return DateTime.fromSQL(dt_str_irish, {zone: 'Europe/Dublin'}).toUTC()
        .toSQL({includeZone: false, includeOffset: false});
}

export default function OrderContainerDialog(props) {
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // const auth = React.useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    const [oc, setOc] = React.useState(undefined);
    // eslint-disable-next-line no-unused-vars
    const [customer, setCustomer] = React.useState(undefined);
    const [customerDirty, setCustomerDirty] = React.useState(false);
    const [order, setOrder] = React.useState(undefined);
    const [unplugAtLocal, setUnplugAtLocal] = React.useState(null);

    //const [savedOc, setSavedOc] = React.useState(undefined);
    const ports = useLiveQuery(
        () => db.Port.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.code]: cur.name}), {}))
        , [], PORTS);
    const depots = useLiveQuery(() => {
        // Add a `label` to the depots which precedes the name with the port name.
        return db.Depot.toArray(arr => arr.map(d => (
            {
                id: d.id,
                label: `${ports[d.port_code] ?? '???'}: ${d.name}`,
                entry_type: d.entry_type,
                port_code: d.port_code,
            }
        )).sort((a, b) => {
            return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
        }));
    }, [ports]);
    // console.log(depots);
    const allStatuses = useLiveQuery(() => db.ContainerStatus.orderBy('sort_order').toArray(), [], []);
    const customers = useLiveQuery(() => db.Customer.orderBy('name').toArray(),
        [], []
    );

    React.useEffect(() => {
        axios.get('/admin/orderContainer/get', {params: {uuid: props.uuid}})
            .then(
                (response) => {
                    const data = response.data;
                    if (!data) {
                        setError('Missing data in response');
                    }
                    else {
                        if (data.error) setError(data.message);
                        else {
                            setOc(data.oc);
                            //setSavedOc(data.oc);    // keep a record of the clean record so we know what's changed.
                            // There's other stuff here like 'port', 'depot', 'customer', 'order' etc too if we need it.
                            setOrder(data.order);
                            setCustomer(data.customer);

                            // unplugAtLocal is oc.unplug_at converted to local time
                            if (data.oc.unplug_at) {
                                setUnplugAtLocal(DateTime.fromSQL(data.oc.unplug_at, {zone: 'utc'}).toLocal());
                            }
                        }
                    }
                },
                (error) => {
                    setError(error.response?.data?.message || error.toString());
                });
    }, [props.uuid]);

    const handleSave = () => {
        console.log('handleSave');

        // Create a copy so we can modify it before saving
        const ocCopy = {
            ...oc
        };

        ocCopy.unplug_at = unplugAtLocal ? unplugAtLocal.toUTC().toFormat(LUXON_MYSQL_DATETIME_FMT) : null;

        try {
            if (!isReeferValid(ocCopy.reefer_num)) throw new Error('Invalid container number');

            const statusRow = allStatuses.find(s => s.status === ocCopy.status);
            if (statusRow) {
                if (!statusRow.has_arrived) {
                    ocCopy.in_at = null;
                    ocCopy.out_at = null;
                } else if (statusRow.has_arrived && !statusRow.has_shipped) {
                    ocCopy.out_at = null;
                    if (!ocCopy.in_at) throw new Error('Please set a value for "Arrived At"');
                } else { //  GONE
                    if (!ocCopy.in_at) throw new Error('Please set a value for "Arrived At"');
                    if (!ocCopy.out_at) throw new Error('Please set a value for "Dispatched At"');

                    // the out_at must be after the in_at
                    if (ocCopy.in_at > ocCopy.out_at) throw new Error('"Dispatched At" must be after "Arrived At"');
                }
            }
            else {
                throw new Error('Invalid status');
            }

            // Trim spaces around ocCopy.ship, and force it to uppercase
            if (ocCopy.ship) {
                ocCopy.ship = ocCopy.ship.trim().toUpperCase();
            }

            setSaving(true);

            // If the customer has changed, just fire off that change first
            // (ignore the response, it's not important enough to care)
            if (customerDirty && customer) {
                axios.post('/admin/container/save', {
                    'reefer_num': ocCopy.reefer_num,
                    'customer_id': customer.id
                });
            }

            axios.post('/admin/orderContainer/update', ocCopy)
                .then(
                    (result) => {
                        console.log(result);

                        if (result.data.error) {
                            setError(result.data);
                        }
                        else {
                            setError(false);
                            enqueueSnackbar(result.data.message || 'Container Record updated', {
                                variant: result.data.warning ? 'warning': 'success'
                            });
                            props.afterSave(result.data.oc);
                        }
                    },
                    (error) => {
                        setError(error.response.data || error);
                    }
                )
                .finally(() => {
                    setSaving(false);
                });

        }
        catch (e) {
            setError(e.message || e.toString());
        }
    };

    const onReeferChange = (event) => {
        //  This gets its own handler to force it to uppercase
        const newNum = event.target.value.toUpperCase().replaceAll(' ', '').substring(0, 11);

        setOc((prev) => ({
            ...prev,
            reefer_num: newNum
        }));
    };

    const onSetTempChange = (event) => {
        // this gets its own handler to ensure that we store it as a number
        setOc((prev) => ({
            ...prev,
            set_temp: event.target.value === '' ? null: +event.target.value
        }));
    };

    const onSetHumidityChange = (event) => {
        // this gets its own handler to ensure that we store it as a number
        setOc((prev) => ({
            ...prev,
            set_humidity: event.target.value === '' ? null: +event.target.value
        }));
    };

    const onShipChange = (event) => {
        // This gets its own handler to we can force to uppercase
        const newShip = event.target.value.toUpperCase();
        setOc((prev) => ({
            ...prev,
            ship: newShip
        }));
    };

    const onFormValueChange = (event) => {
        setOc((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }))
    }

    const onAtDateChange = (e, fld) => {
        const newValIn = e.target.value;
        let newVal = null;
        if (newValIn) {
            newVal = slotToDateTime(newValIn, dateTimeToSlot(oc[fld]) ?? 'AM');
        }
        setOc((prev) => ({
            ...prev,
            [fld]: newVal,
        }));
    }

    const onAtSlotChange = (e, fld) => {
        let date_part = oc[fld] ? oc[fld].substring(0, 10) : null;
        if (!date_part) date_part = DateTime.utc().toSQLDate();
        const slot = e.target.value;
        if (!slot) {
            // Deselect the slot? Do nothing
            return;
        }
        const date_str_irish = date_part + ' ' + MAGIC_TIME_INV[slot];
        const newAt = DateTime.fromSQL(date_str_irish, {zone: 'Europe/Dublin'}).toUTC().toSQL({includeZone: false, includeOffset: false});
        setOc((prev) =>({
            ...prev,
            [fld]: newAt
        }));
    }

    const setContainerCustomer = (customer_id) => {
        if (!customer_id) return;
        // also need to set the local `customer`
        setCustomer(customers.find(c => c.id === customer_id));
        setCustomerDirty(true);
    }

    if (oc === undefined || depots === undefined) {
        return (
            <Dialog open={true} onClose={() => props.onCancel()} fullScreen={fullScreen}>
                <DialogContent>
                    Loading...
                </DialogContent>
            </Dialog>
        );
    }

    const isValid = isReeferValid(oc.reefer_num);
    const statusRow = allStatuses.find(s => s.status === oc.status);
    const canDelete = props.deleteFn && !oc.invoice_id;

    // console.log(order, depots);
    console.log('oc', oc.unplug_only, unplugAtLocal?.toISO(), oc.is_unplugged, oc.unplug_at);

    return (
        <form>
            <Dialog open={true} onClose={() => props.onCancel()} fullScreen={fullScreen} fullWidth maxWidth="xl">
                <DialogTitle>Edit Container {formatReefer(oc.reefer_num)}</DialogTitle>

                <DialogContent dividers={true}>
                    {error && <Alert severity="error">{error.message || error}</Alert>}

                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4} lg={2}>
                            <TextField
                                autoFocus
                                fullWidth
                                // margin="dense"
                                id="reefer_num"
                                name="reefer_num"
                                label="Container Number"
                                helperText="Reefer Number"
                                type="text"
                                variant="standard" size="small"
                                required={true}
                                error={!isReeferValid(oc.reefer_num)}
                                value={oc.reefer_num ? formatReefer(oc.reefer_num): ''}
                                onChange={onReeferChange}
                                sx={{maxWidth: '11em'}}
                                inputProps={{spellCheck: 'false', size: 13, maxLength: 14}}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} xl={2}>
                            <TextField
                                fullWidth
                                // margin="dense"
                                id="depot_id"
                                name="depot_id"
                                select
                                label="Terminal/Depot"
                                helperText="Port: Terminal or Depot"
                                variant="standard" size="small"
                                required={true}
                                value={oc.depot_id}
                                onChange={onFormValueChange}

                            >{
                                // If the container is part of an order with a port, restrict depots to that port.
                                (order?.port_code ? depots?.filter(d => d.port_code === order.port_code) : depots)?.map(d => (
                                    <MenuItem key={d.id} value={d.id}>{d.label}</MenuItem>
                                ))
                            }</TextField>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} xl={1}>
                            <TextField
                                // margin="dense"
                                id="set_temp"
                                name="set_temp"
                                label="Set Temp"
                                helperText="Container Setting"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                                }}
                                type="number"
                                variant="standard" size="small"
                                inputProps={{ min: -35.0, max: 35.0, step: 0.1, maxLength: 5, size: 5 }}
                                required={true}
                                value={oc.set_temp == null ? '' : oc.set_temp}
                                // sx={{mr: 1}}
                                onChange={onSetTempChange}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} xl={1}>
                            <TextField
                                // margin="dense"
                                id="set_humidity"
                                name="set_humidity"
                                label="Set Humidity"
                                helperText="Container Setting"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                type="number"
                                variant="standard" size="small"
                                inputProps={{ min: 0, max: 100.0, step: 1, maxLength: 5, size: 5 }}
                                required={false}
                                value={oc.set_humidity == null ? '' : oc.set_humidity}
                                // sx={{mr: 1}}
                                onChange={onSetHumidityChange}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} xl={1}>
                            <TextField
                                id="ie"
                                name="ie"
                                label="I/E Cycle"
                                helperText="Imp/Exp Cycle"
                                select
                                variant="standard" size="small"
                                value={oc.ie ?? ''}
                                // sx={{mr: 1}}
                                onChange={onFormValueChange}
                            >
                                <MenuItem key='na' value={''}> - </MenuItem>
                                <MenuItem key='I' value={'I'}>I</MenuItem>
                                <MenuItem key='E' value={'E'}>E</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} xl={1}>
                            <TextField
                                // margin="dense"
                                id="vent"
                                name="vent"
                                label="Vent"
                                helperText="Vent Setting"
                                select
                                variant="standard" size="small"
                                value={oc.vent}
                                // sx={{mr: 1}}
                                onChange={onFormValueChange}
                            >{
                                Object.entries(VENT_POSNS).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
                            }</TextField>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} xl={1}>
                            <TextField
                                fullWidth
                                // margin="dense"
                                id="status"
                                name="status"
                                select
                                label="Status"
                                helperText="Current Status"
                                variant="standard" size="small"
                                required={true}
                                value={oc.status}
                                onChange={onFormValueChange}

                            >{
                                allStatuses.map(s => <MenuItem key={s.status} value={s.status}>{s.status_name}</MenuItem>)
                            }</TextField>
                        </Grid>
                        {
                            (statusRow && statusRow.has_arrived) ?
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box display="flex">
                                    <TextField
                                        // margin="dense"
                                        variant="standard" size="small"
                                        id="in_at_date"
                                        name="in_at_date"
                                        label="Arrived At"
                                        type="date"
                                        value={oc.in_at ? oc.in_at.substring(0, 10): ''}
                                        required={true}
                                        sx={{ minWidth: 140 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            max: DateTime.local().toSQLDate(), // can't be later than today
                                        }}
                                        onChange={(e) => onAtDateChange(e, 'in_at')}
                                    />
                                    <ToggleButtonGroup
                                        size="small"
                                        aria-label="Time Slot"
                                        sx={{ml: 1, alignSelf: 'flex-end'}}
                                        value={dateTimeToSlot(oc.in_at)}
                                        aria-required
                                        exclusive
                                        onChange={(e) => onAtSlotChange(e, 'in_at')}
                                    >
                                        <ToggleButton value='AM' key='AM'>AM</ToggleButton>
                                        <ToggleButton value='MD' key='MD'>MD</ToggleButton>
                                        <ToggleButton value='PM' key='PM'>PM</ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Grid> : null
                        }
                        {
                            (statusRow && statusRow.has_shipped) ?
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box display="flex">
                                    <TextField
                                        // margin="dense"
                                        variant="standard" size="small"
                                        id="out_at_date"
                                        name="out_at_date"
                                        label="Dispatched At"
                                        type="date"
                                        value={oc.out_at ? oc.out_at.substring(0, 10): ''}
                                        required={true}
                                        sx={{ minWidth: 140 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            max: DateTime.local().toSQLDate(), // can't be later than today
                                        }}
                                        onChange={(e) => onAtDateChange(e, 'out_at')}
                                    />
                                    <ToggleButtonGroup
                                        size="small"
                                        aria-label="Time Slot"
                                        sx={{ml: 1, alignSelf: 'flex-end'}}
                                        value={dateTimeToSlot(oc.out_at)}
                                        aria-required
                                        exclusive
                                        onChange={(e) => onAtSlotChange(e, 'out_at')}
                                    >
                                        <ToggleButton value='AM' key='AM'>AM</ToggleButton>
                                        <ToggleButton value='MD' key='MD'>MD</ToggleButton>
                                        <ToggleButton value='PM' key='PM'>PM</ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Grid> : null
                        }
                        {/*<Grid item xs={6} sm={4} md={3} lg={2}>*/}
                        {/*    <TextField*/}
                        {/*        // margin="dense"*/}
                        {/*        variant="standard" size="small"*/}
                        {/*        id="due_date"*/}
                        {/*        name="due_date"*/}
                        {/*        label="Due Date"*/}
                        {/*        helperText="Expected Arrival"*/}
                        {/*        type="date"*/}
                        {/*        value={oc.due_date ?? ''}*/}
                        {/*        sx={{ minWidth: 140 }}*/}
                        {/*        InputLabelProps={{*/}
                        {/*            shrink: true,*/}
                        {/*        }}*/}
                        {/*        onChange={onFormValueChange}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                            <TextField
                                // margin="dense"
                                variant="standard" size="small"
                                id="sail_date"
                                name="sail_date"
                                label="Vessel Date"
                                helperText="Expected Arr/Dept"
                                type="date"
                                value={oc.sail_date ?? ''}
                                sx={{ minWidth: 140 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={onFormValueChange}
                            />
                        </Grid>
                        {
                            !oc.order_id && customers.length && customer && (
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                    <TextField
                                        // margin="dense"
                                        variant="standard" size="small"
                                        id="cont_customer"
                                        name="cont_customer"
                                        label="Customer"
                                        helperText="Customer Name"
                                        select
                                        fullWidth
                                        value={ customer.id }
                                        onChange={(e) => {
                                            setContainerCustomer(e.target.value);
                                        }}
                                    >
                                        {
                                            customers.map((d) => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)
                                        }
                                    </TextField>
                                </Grid>
                            )
                        }
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                            <TextField
                                // margin="dense"
                                variant="standard" size="small"
                                id="ship"
                                name="ship"
                                label="Ship"
                                helperText="Vessel Name"
                                type="text"
                                value={oc.ship ?? ''}
                                sx={{ minWidth: 140 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={onShipChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                // margin="dense"
                                id="cargo_description"
                                name="cargo_description"
                                label="Cargo Description"
                                helperText="Container Contents"
                                type="text"
                                variant="standard" size="small"
                                value={oc.cargo_description ?? ''}
                                onChange={onFormValueChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={4}>
                            <TextField
                                fullWidth
                                // margin="dense"
                                id="customer_note"
                                name="customer_note"
                                label="Customer Note"
                                helperText="Customer Note"
                                type="text"
                                variant="standard" size="small"
                                value={oc.customer_note ?? ''}
                                onChange={onFormValueChange}
                            />
                        </Grid>
                        {/*<Grid item xs={6} sm={4} md={2} xl={1}>*/}
                        {/*    <TextField*/}
                        {/*        fullWidth*/}
                        {/*        // margin="dense"*/}
                        {/*        id="release_no"*/}
                        {/*        name="release_no"*/}
                        {/*        label="Release No"*/}
                        {/*        helperText="Release Number"*/}
                        {/*        type="text"*/}
                        {/*        variant="standard" size="small"*/}
                        {/*        value={oc.release_no ?? ''}*/}
                        {/*        onChange={onFormValueChange}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={6} sm={4} md={2} xl={1}>*/}
                        {/*    <TextField*/}
                        {/*        fullWidth*/}
                        {/*        // margin="dense"*/}
                        {/*        id="voyage_number"*/}
                        {/*        name="voyage_number"*/}
                        {/*        label="Voyage No"*/}
                        {/*        helperText="Voyage Number"*/}
                        {/*        type="text"*/}
                        {/*        variant="standard" size="small"*/}
                        {/*        value={oc.voyage_number ?? ''}*/}
                        {/*        onChange={onFormValueChange}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={6} sm={4} md={2} xl={1}>
                            <TextField
                                fullWidth
                                // margin="dense"
                                id="booking_ref"
                                name="booking_ref"
                                label="Booking Ref"
                                helperText="Booking Ref"
                                type="text"
                                variant="standard" size="small"
                                value={oc.booking_ref ?? ''}
                                onChange={onFormValueChange}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} xl={1}>
                            <FormControlLabel control={
                                    <Switch
                                        checked={!!oc.unplug_only}
                                        onChange={(e) => onFormValueChange({target: {name: 'unplug_only', value: e.target.checked ? 1: 0}})}
                                        color="primary"
                                    />
                                }
                                label="Unplug-Only"
                                labelPlacement="top"
                            />
                        </Grid>
                        {
                            (!!oc.unplug_only) &&
                            <>
                                <Grid item xs={6} sm={3} md={2} xl={1}>
                                    <TextField
                                        fullWidth
                                        // margin="dense"
                                        id="unplug_at_date"
                                        name="unplug_at_date"
                                        label="Unplug At (Date)"
                                        helperText="Requested Unplug Date"
                                        type="date"
                                        variant="standard" size="small"
                                        value={unplugAtLocal ? unplugAtLocal.toISODate(): ''}
                                        sx={{ minWidth: 140 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            const newVal = e.target.value;
                                            // We're just changing the date part of unplugAtLocal here, so keep the time
                                            if (!newVal) {
                                                setUnplugAtLocal(null);
                                            }
                                            else {
                                                const oldTime = unplugAtLocal ? unplugAtLocal.toFormat('HH:mm:ss') : '12:00:00';
                                                setUnplugAtLocal(DateTime.fromSQL(newVal + ' ' + oldTime, {zone: 'local'}));
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} md={2} xl={1}>
                                    <TextField
                                        // margin="dense"
                                        id="unplug_at_time"
                                        name="unplug_at_time"
                                        label="Unplug At (Time)"
                                        helperText="Requested Unplug Time"
                                        type="time"
                                        variant="standard" size="small"
                                        value={unplugAtLocal ? unplugAtLocal.toFormat('HH:mm'): ''}
                                        sx={{ minWidth: 140 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            const newVal = e.target.value;
                                            // We're just changing the time part of unplug_at here, so keep the date
                                            if (!newVal) {
                                                setUnplugAtLocal(null);
                                            }
                                            else {
                                                const oldDate = unplugAtLocal ? unplugAtLocal.toISODate() : DateTime.local().toISODate();
                                                setUnplugAtLocal(DateTime.fromSQL(oldDate + ' ' + newVal, {zone: 'local'}));
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} md={2} xl={1}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!!oc.is_unplugged}
                                                onChange={(e) => onFormValueChange({target: {name: 'is_unplugged', value: e.target.checked ? 1: 0}})}
                                                color="primary"
                                            />
                                        }
                                        label="Unplugged"
                                        labelPlacement="top"
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {canDelete && <Button
                        onClick={() => props.deleteFn(oc.uuid)}
                        color="warning" sx={{mr: 'auto'}}
                        title="Delete this container"
                    >Delete</Button>}
                    {props.addToOrderFn && !oc.order_id && <Button
                        onClick={() => props.addToOrderFn(oc)}
                        color="secondary" sx={{mr: 'auto'}}
                        title="Add this container to a Shipping List"
                    >Add To Shipping List</Button>}
                    <Button onClick={() => props.onCancel()}>Cancel</Button>
                    <Button type="submit" onClick={handleSave} disabled={!isValid || saving}>Save</Button>
                </DialogActions>
            </Dialog>
        </form>
    )
}
OrderContainerDialog.propTypes = {
    uuid: PropTypes.string.isRequired,
    afterSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    deleteFn: PropTypes.func,
    addToOrderFn: PropTypes.func,
}

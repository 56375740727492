import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import {DataGrid} from "@mui/x-data-grid";
import {PORTS, prettyDateTime} from "../../../common/shared";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../../common/db";


export default function SelectOrderDialog(props) {
    const {title, portCode, ocUuid, onCancel, onOrderSelected} = props;

    const [error, setError] = React.useState(undefined);
    const [loading, setLoading] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [orders, setOrders] = React.useState(undefined);
    const [selectedOrder, setSelectedOrder] = React.useState(null);
    const ports = useLiveQuery(
        () => db.Port.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.code]: cur.name}), {}))
        , [], PORTS);
    const allDepotsById = useLiveQuery(
        () => db.Depot.toArray((arr) => arr.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {})),
        [], {});
    const users = useLiveQuery(() => db.User.toArray(), [], [])


    React.useEffect(() => {
        setLoading(true);
        axios.get('/admin/order/index', {params: {portCode, ocUuid}})
            .then(
                (response) => {
                    const data = response.data;
                    console.log(data);
                    if (!data) {
                        setError('Missing data in response');
                    }
                    else {
                        if (data.error) setError(data.message);
                        else {
                            setOrders(data.result);
                        }
                    }
                },
                (error) => {
                    setError(error.response?.data?.message || error.toString());
                })
            .finally(() => setLoading(false));
    }, [portCode, ocUuid]);

    const onAddToOrder = () => {
        const o = orders.find(o => o.id === selectedOrder);
        onOrderSelected(o);
    }

    const columns = React.useMemo(() => {
        return [
            {
                field: 'id',
                headerName: 'Shipping List Id',
                type: 'number',
                width: 100,
            },
            {
                field: 'customer_id',
                headerName: 'Customer',
                valueGetter: ({row}) => row.customer?.name ?? ' - None - ',
                minWidth: 80,
                flex: 2,
            },
            {
                field: 'port_code',
                headerName: 'Port',
                valueFormatter: ({value}) => ports[value] ?? '- None -',
                width: 80,
            },
            {
                field: 'import_export',
                headerName: 'Imp/Exp',
                valueGetter: ({value}) => ({IMP: 'Import', EXP: 'Export'}[value]),
                width: 100,
            },
            {
                field: 'booking_ref',
                headerName: 'Ship Ref',
                minWidth: 80,
                flex: 1,
            },
            {
                field: 'terminal_id',
                headerName: 'Terminal',
                valueGetter: ({row}) => row.terminal_id ? allDepotsById[row.terminal_id]?.name : '- None -',
            },
            {
                field: 'created_at',
                headerName: 'Created',
                type: 'date',
                valueFormatter: ({value}) => prettyDateTime(value),
                minWidth: 100,
                flex: 0.5,
            },
            {
                field: 'created_by',
                headerName: 'Created By',
                valueGetter: ({value}) => {
                    const u = users.find(u => u.id === value);
                    if (u) return `${u.firstname} ${u.surname}`;
                    return '???';
                },
                minWidth: 100,
                flex: 2,
            },
            {
                field: 'numContainers',
                headerName: '# Cont.',
                type: 'number',
                width: 100,
            }
        ];
    }, [ports, users, allDepotsById]);

    return (
        <Dialog open={true} onClose={() => onCancel()} fullScreen={fullScreen} fullWidth maxWidth="xl">
            <DialogTitle>{title || 'Select Shipping List'}</DialogTitle>
            <DialogContent sx={{p:0, height: 'calc(100vh - 200px)', overflowY: 'hidden'}}>
                <div style={{ display: 'flex', height: '100%'}}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            density="compact"
                            columns={columns}
                            rows={orders ?? []}
                            error={error}
                            loading={loading}
                            sx={{
                                bgcolor: '#e2e0e0',
                                borderRadius: 0,
                                '& .MuiDataGrid-cell:focus': {
                                    outline: "none",
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    height: '40px',
                                },
                                '& .MuiDataGrid-row': {
                                    bgcolor: 'white'
                                },
                            }}
                            hideFooterSelectedRowCount
                            // disableColumnFilter
                            disableDensitySelector
                            // disableColumnMenu
                            initialState={{
                                sorting: {
                                    // sort by 'created_at desc' by default
                                    sortModel: [{field: 'created_at', sort: 'desc'}]
                                },
                                columns: {
                                    // hide the port and created_by columns. Usually unneeded.
                                    columnVisibilityModel: { port_code: false, created_by: false }
                                }
                            }}
                            onSelectionModelChange={(newSelMod) => {
                                setSelectedOrder(newSelMod?.[0] ?? null);
                            }}
                            selectionModel={[selectedOrder]}
                            onRowDoubleClick={() => selectedOrder && onAddToOrder()}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onCancel()}>Cancel</Button>
                <Button type="submit" onClick={() => onAddToOrder()} disabled={!selectedOrder}>Add to Order</Button>
            </DialogActions>
        </Dialog>
    );
}

SelectOrderDialog.propTypes = {
    title: PropTypes.string,
    ocUuid: PropTypes.string,
    portCode: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onOrderSelected: PropTypes.func.isRequired,
}